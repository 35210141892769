import React from 'react';
import { motion } from 'framer-motion';
import OrderHeroText from '../../assets/images/OrderHero.png'; 
import OrderNowButton from "../../assets/images/OrderNowButton.png"; 
import { ReactComponent as SeparatorSvg } from '../../assets/images/HomeVerticalSeperator.svg';

const OrderHero: React.FC<{ onScrollToMenu: () => void }> = ({ onScrollToMenu }) => {
  return (
    <div className="h-screen p-0 bg-black flex flex-col lg:flex-row items-center justify-center relative">
      {/* LEFT SECTION */}
      <div className="pt-[15vh] p-8 flex flex-col items-center w-full lg:w-1/2 h-full text-white relative">
        {/* Similar to CateringHero approach */}
        <div className="flex flex-col items-center justify-center w-full h-full text-center px-4">
          {/* Title Image */}
          <div className="relative w-[320px] sm:w-[400px] md:w-[500px] mx-auto mb-6">
            <img
              src={OrderHeroText}
              alt="Order Now Text"
              className="relative w-full h-auto"
            />
          </div>

          {/* Short Paragraph */}
          <p className="font-myriad text-base md:text-md lg:text-lg text-redz-white max-w-2xl pt-8">
            Enjoy the best of Redz from the comfort of your home or on the go.
            Choose from our extensive menu and customize your order with your
            favorite proteins. Whether you’re craving our award-winning birria
            or a refreshing drink, we’ve got something for everyone.
          </p>

          {/* "Order Now" Button as an image */}
          <div className="mt-8">
            <motion.a
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              onClick={onScrollToMenu}  // triggers scroll
              className="inline-block cursor-pointer"
            >
              <img
                src={OrderNowButton}
                alt="Order Now Button"
                className="w-84 h-auto"
              />
            </motion.a>
          </div>
        </div>
      </div>

      {/* RIGHT SECTION (optional) */}
      <div className="relative w-1/2 h-full hidden lg:block z-40">
        {/* Image covering the right half of the screen */}
        <div className="absolute inset-0 z-40">
            <img
                src={"https://redz-bucket.s3.amazonaws.com/Lunch%20Special.webp"}
                alt="Tacos"
                className="h-full w-full object-cover z-40"
            />
        </div>
        {/* Separator positioned at the left edge of the image, with only y-overflow hidden */}
        <div className="absolute left-[-3.8em] bottom-0 h-full z-50 overflow-y-hidden">
            <SeparatorSvg className="w-[6em]" />
        </div>
    </div>
    </div>
  );
};

export default OrderHero;