import React from 'react';
import FooterLogo from './FooterLogo';
import FooterNavLinks from './FooterNavLinks';
// Import the FooterLine SVG as a React component
import { ReactComponent as FooterLine } from '../../../assets/images/FooterLine.svg';

const Footer: React.FC = () => {
  return (
<footer>
  <div className='bg-black text-redz-white px-6 py-8 xl:px-56 xl:py-24 relative z-10 overflow-y-hidden'>
    <div className="flex flex-col items-center justify-center space-y-8">
      <FooterLogo />
      <FooterNavLinks />
    </div>
  </div>
  {/* FooterLine SVG placed in normal flow so layout is stable */}
  <div className="overflow-x-hidden w-full mt-8">
    <FooterLine className="w-full h-auto" />
  </div>
</footer>
  );
};

export default Footer;