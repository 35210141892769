import React from 'react';
import { motion } from 'framer-motion';

interface LocationSectionProps {
  title?: string;
  description?: string;
}

const LocationSection: React.FC<LocationSectionProps> = ({
  title = 'LOCATIONS',
  description
}) => {
  return (
    <section className="relative bg-redz-white overflow-hidden">
      {/* Header Border */}
      {/* On small screens, let’s reduce the negative top offset */}
      <div className="absolute w-[120%] top-[-2.4em] md:top-[-4.4em] translate-x-[-10%] z-10">
        <img
          src="https://redz-bucket.s3.amazonaws.com/LocationHeaderBorder.svg"
          alt="Location Header Border"
          className="w-full"
        />
      </div>

      <div className="container mx-auto py-20 md:py-24 px-4 md:px-8 lg:px-16 relative z-20">
        {/* Title + Optional Description */}
        <div className="text-center mb-8 md:mb-12">
          <h2 className="font-nexa text-redz-red text-4xl md:text-6xl">{title}</h2>
          {description && (
            <p className="font-redsox text-black text-base md:text-lg lg:text-xl mt-3 md:mt-4">
              {description}
            </p>
          )}
        </div>

        {/* Row 1: Yona Redz (Left) & Hours/Contact (Right) */}
        <div className="flex flex-col lg:flex-row justify-between">
          {/* Left Column: Yona Redz Map + Address */}
          <div className="w-full lg:w-1/2 text-center space-y-4 mb-8 lg:mb-0 lg:pr-8 px-4 lg:px-0">
            <p className="font-redsox text-black text-base md:text-lg lg:text-xl">
              <strong>Yona Redz</strong>
              <br />
              1230 State St. Suite C
              <br />
              Santa Barbara, CA 93101
            </p>

            {/* Responsive Wrapper for Google Map */}
            <div className="w-full h-0 pb-[56.25%] relative overflow-hidden shadow-lg rounded-md mt-4">
              <iframe
                title="Yona Redz Google Map"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d6582.193667184972!2d-119.70788402341732!3d34.42429537301909!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80e915447d06cb7f%3A0xb2d8d13b5897954e!2sYona%20Redz!5e0!3m2!1sen!2sus!4v1739463224259!5m2!1sen!2sus"
                style={{ border: 0 }}
                loading="lazy"
                allowFullScreen
                referrerPolicy="no-referrer-when-downgrade"
                className="absolute top-0 left-0 w-full h-full"
              />
            </div>
          </div>

          {/* Right Column: Hours, Phone, Email */}
          <div className="w-full lg:w-1/2 text-center space-y-6 lg:pl-8 px-4 lg:px-0">
            {/* Phone Number */}
            <h2 className="font-nexa text-black text-3xl md:text-4xl lg:text-5xl">
              <span className="font-myriad">(</span>805
              <span className="font-myriad">)</span> 679-5657
            </h2>

            {/* Email */}
            <p className="font-nexa text-redz-red text-lg md:text-xl lg:text-2xl w-full break-words overflow-hidden">
              YONAREDZSB<span className="font-myriad">@</span>YAHOO.COM
            </p>

            {/* Hours Section */}
            <div className="flex flex-col md:flex-row justify-center items-center md:space-x-3">
              <h3 className="font-nexa text-black text-2xl md:text-3xl mb-2 md:mb-0">
                HOURS
              </h3>
              <img
                src="https://redz-bucket.s3.amazonaws.com/Maraccas.svg"
                alt="Maracas"
                className="w-10 h-10 md:w-12 md:h-12"
              />
            </div>

            <div className="font-redsox text-black text-base md:text-lg text-center space-y-4">
              <p>
                Monday - Thursday
                <br />
                11:00AM - 9:00PM
              </p>
              <p>
                Friday - Saturday
                <br />
                11:00AM - Midnight
              </p>
              <p>
                Sunday
                <br />
                11:00AM - 9:00PM
              </p>
            </div>
          </div>
        </div>

        {/* Row 2: Pop-Up at Captain Fatty's */}
        <div className="mt-12">
          <div className="flex flex-col md:flex-row items-center justify-between space-y-8 md:space-y-0">
            {/* Pop-Up Info */}
            <div className="w-full md:w-1/2 text-center md:text-left md:pr-8 px-4 md:px-0">
              <h4 className="font-nexa text-redz-red text-xl md:text-2xl mb-2">
                Pop-Up at Captain Fatty's
              </h4>
              <p className="font-redsox text-black text-base md:text-lg">
                <strong>Tuesdays &amp; Thursdays</strong>
                <br />
                5:00PM - 9:00PM
                <br />
                6489 Calle Real Suite D, Goleta, CA 93117
              </p>

              {/* NEW BUTTON / LINK TO THE MENU */}
              <div className="mt-4">
                <motion.a
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  href="https://redz-bucket.s3.us-west-2.amazonaws.com/Redz%20Fatty%20Menu.webp"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="inline-block bg-redz-red text-white font-nexa py-2 px-6 rounded-md shadow-md
                             transition-colors duration-300 hover:bg-[#ad2419]"
                >
                  View Pop-Up Menu
                </motion.a>
              </div>
            </div>

            {/* Smaller Map Iframe */}
            <div className="w-full md:w-1/2 text-center px-4 md:px-0">
              {/* We'll keep this smaller but responsive */}
              <div className="mx-auto shadow-lg rounded-md max-w-md w-full h-0 pb-[56.25%] relative">
                <iframe
                  title="Captain Fatty's Google Map"
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d441.508015907929!2d-119.85240439921735!3d34.43923927425128!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80e940913937b17d%3A0xe22b216bfe5126c2!2sCaptain%20Fatty&#39;s%20Brewery!5e0!3m2!1sen!2sus!4v1739463083583!5m2!1sen!2sus"
                  style={{ border: 0 }}
                  allowFullScreen
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                  className="absolute top-0 left-0 w-full h-full rounded-md"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Footer Border */}
      {/* We can hide or reduce it on small screens if it looks odd */}
      <div className="w-full mt-8 md:mt-16 translate-y-[5%] md:translate-y-[10%]">
        <img
          src="https://redz-bucket.s3.amazonaws.com/CactusFooter.svg"
          alt="Cactus Footer"
          className="w-full"
        />
      </div>
    </section>
  );
};

export default LocationSection;