import React from 'react';
import { ReactComponent as TitleUnderline } from '../../assets/images/TitleUnderline.svg';
import TacoAccent from '../common/TacoAccent';

interface SectionTitleProps {
  title: string;
  alternate?: boolean;
  left?: boolean;
  black?: boolean;
  TacoColor?: string
}

export default function SectionTitle({ title, alternate, left, black, TacoColor }: SectionTitleProps) {
  return (
    <div
      // When `left` is true, we center on small screens and align-left (items-start) on md+.
      // For sections without `left`, we keep them always centered.
      className={`flex flex-col mb-8
        ${left ? 'items-center md:items-start md:ml-4' : 'items-center'}
      `}
    >
      {/* 0th row: Title underline (appears only if `alternate` is true) */}
      {alternate && (
        <div
          className={`
            flex w-full mt-2
            ${left ? 'justify-center md:justify-start' : 'justify-center'}
          `}
        >
          <TitleUnderline
            className={`w-auto h-4 md:h-6 ${left ? 'md:ml-8' : ''}`}
          />
        </div>
      )}

      {/* First row: Taco accents + Title */}
      <div
        className={`
          flex items-center w-full my-12
          ${left ? 'justify-center md:justify-start' : 'justify-center'}
        `}
      >
        {/* Left Taco Accent */}
        <TacoAccent
          color={TacoColor || ''}
          className={`hidden md:block w-6 md:w-10 h-auto transform -translate-y-[30%] ${
            !alternate
              ? '-translate-y-[15%]'
              : 'rotate-[40deg] -scale-x-100 -translate-x-[40%] translate-y-[10%] w-10 md:w-14'
          }`}
          aria-hidden="true"
        />

        {/* Title */}
        <h3
          className={`
            relative text-5xl md:text-7xl font-nexa 
            ${black ? 'text-black': 'text-redz-white'}
            ${left ? 'text-center md:text-left' : 'text-center'}
          `}
        >
          {title}
        </h3>

        {/* Right Taco Accent */}
        <TacoAccent
          color={TacoColor || ''}
          className={`hidden md:block w-6 md:w-10 h-auto transform rotate-[115deg] -translate-y-[30%] ${
            !alternate
              ? '-translate-y-[15%]'
              : '-rotate-[143deg] -scale-x-100 translate-x-[40%] translate-y-[10%] w-10 md:w-14'
          }`}
          aria-hidden="true"
        />
      </div>

      {/* Second row: Title underline (always shown) */}
      <div
        className={`
          flex w-full mt-2
          ${left ? 'justify-center md:justify-start' : 'justify-center'}
        `}
      >
        <TitleUnderline
          className={`w-auto h-4 md:h-6 ${left ? 'md:ml-8' : ''}`}
        />
      </div>
    </div>
  );
}