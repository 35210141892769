import React from 'react';
import { Link } from 'react-router-dom';

const Congratulations: React.FC = () => {
  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-redz-white text-redzText p-6">
      <h1 className="text-3xl md:text-5xl font-nexa text-redz-red mb-6">
        Congratulations!
      </h1>
      <p className="font-myriad text-black text-lg md:text-xl text-center max-w-xl mb-8">
        Thank you for your order. Your payment has been received, and our team
        is getting everything ready. We'll send an email with more details
        shortly.
      </p>

      <Link
        to="/"
        className="bg-redz-red font-redsox text-black py-3 px-6  hover:bg-opacity-90 transition-colors"
      >
        Return to Home
      </Link>
    </div>
  );
};

export default Congratulations;