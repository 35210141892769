import React, { FC, ReactNode } from "react";
import Footer from "./Footer/Footer";
import { ScrollRestoration, useLocation } from "react-router-dom";
import Header, { OnlineOrderTag } from "./Header/index";
import { AnimatePresence } from "framer-motion";
import { ToastContainer } from "react-toastify";

// Importing the SVG as a URL
import VerticalBorders from "../../assets/images/VerticalBorders.svg";

interface RootProps {
  children?: ReactNode;
}



const Root: FC<RootProps> = ({ children }) => {
  const location = useLocation();
  const { pathname } = location;

  return (
    <div className="relative flex flex-col min-h-screen text-white bg-black">
      <AnimatePresence>
        {/* Give each direct child a unique "key" */}
        <Header key="header" />

        {/* 
          LEFT BORDER
          Using inline style for backgroundImage, plus Tailwind for repeat-y and positioning.
        */}
        <div
          key="left-border"
          className="
            pointer-events-none
            hidden
            md:block
            absolute
            top-0
            bottom-0
            left-0
            w-8
            z-20
            bg-repeat-y 
            bg-left
          "
          style={{
            backgroundImage: `url(${VerticalBorders})`,
            backgroundSize: "auto",
            backgroundRepeat: "repeat-y",
          }}
        />

        {/* 
          RIGHT BORDER
        */}
        <div
          key="right-border"
          className="
            pointer-events-none
            hidden
            md:block
            absolute
            top-0
            bottom-0
            right-0
            w-8
            z-20
            bg-repeat-y
            bg-right
          "
          style={{
            backgroundImage: `url(${VerticalBorders})`,
            backgroundSize: "auto",
            backgroundRepeat: "repeat-y",
          }}
        />

        {/* MAIN CONTENT */}
        <div key="main-content" className="flex-grow relative overflow-x-hidden">
          {children}
        </div>

        { pathname !== '/order' &&
          <div className='fixed bottom-2 right-0 -translate-x-1/2 md:hidden z-50'>
          <OnlineOrderTag/>
        </div>
        }

        <Footer key="footer" />

        <ToastContainer
          key="toast"
          position="top-right"
          className="mt-36"
          autoClose={2500}
        />

        <ScrollRestoration
          key="scroll-restoration"
          getKey={(location) => location.pathname}
        />
      </AnimatePresence>
    </div>
  );
};

export default Root;