// Sunday=0, Monday=1, Tuesday=2, Wed=3, Thu=4, Fri=5, Sat=6

export function isOpenForBusinessLocal(): boolean {
    const laTimeStr = new Date().toLocaleString("en-US", {
      timeZone: "America/Los_Angeles",
    });
    const laNow = new Date(laTimeStr);
  
    const day = laNow.getDay(); // 0..6
    const hour = laNow.getHours(); // 0..23
  
    // Sunday–Thursday => 0..4 actually means Sunday=0, Monday=1, Tuesday=2, 
    //   Wed=3, Thu=4. But your posted hours said Sunday–Thursday => 6,0,1,2,3 in Python.
    //   We must carefully adapt:
    //
    //   Sunday = 0 => open 11 <= hour < 21
    //   Monday = 1 => open 11 <= hour < 21
    //   Tuesday= 2 => open 11 <= hour < 21
    //   Wednesday=3 => open 11 <= hour < 21
    //   Thursday=4 => open 11 <= hour < 21
    //   Friday=5 => open 11 <= hour < 24
    //   Saturday=6 => open 11 <= hour < 24
  
    if (day >= 0 && day <= 4) {
      // Sunday(0)–Thursday(4) => 11:00am–9:00pm
      return hour >= 11 && hour < 21;
    } else if (day === 5 || day === 6) {
      // Friday=5, Saturday=6 => 11:00am–11:59pm
      return hour >= 11 && hour < 24;
    }
    return false;
  }