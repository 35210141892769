// src/components/Catering/BookNowModal.tsx

import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { toast } from "react-toastify";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import cateringStore from "../../../stores/CateringStore";

interface BookNowModalProps {
  onClose: () => void;
}

/**
 * A modal overlay that:
 * 1) Calls `bookCatering()` on mount to create a PaymentIntent (storing `clientSecret`).
 * 2) Displays a summary of the user's catering info.
 * 3) Has a payment <form> with <CardElement> so user can pay inside the modal.
 */
const BookNowModal: React.FC<BookNowModalProps> = observer(({ onClose }) => {
  const stripe = useStripe();
  const elements = useElements();

  const [clientSecret, setClientSecret] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [isPaying, setIsPaying] = useState(false);

  const {
    selectedPackage,
    guestCount,
    eventDate,
    eventType,
    serviceHours,
    name,
    email,
    phone,
    subtotal,
    serviceFee,
    total,
    bookCatering,
  } = cateringStore;

  // On mount => create PaymentIntent
  useEffect(() => {
    if (!selectedPackage) {
      toast.error("No package selected. Please pick a package first.");
      onClose();
      return;
    }
    createPaymentIntent();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function createPaymentIntent() {
    setLoading(true);
    try {
      const { clientSecret } = await bookCatering();
      setClientSecret(clientSecret);
    } catch (err: any) {
      toast.error("Failed to create PaymentIntent: " + err.message);
      onClose();
    } finally {
      setLoading(false);
    }
  }

  // If no package => do nothing
  if (!selectedPackage) return null;

  const showCardForm = !!clientSecret && !loading;

  // Called when the user clicks the form's "Pay Now" button
  async function handleSubmitPayment(e: React.FormEvent) {
    e.preventDefault();
    if (!stripe || !elements) return;
    if (!clientSecret) {
      toast.error("No payment session yet. Please wait...");
      return;
    }

    setIsPaying(true);

    const cardElement = elements.getElement(CardElement);
    if (!cardElement) {
      toast.error("CardElement not found");
      setIsPaying(false);
      return;
    }

    try {
      // Attempt to confirm card payment with the existing PaymentIntent
      const result = await stripe.confirmCardPayment(clientSecret, {
        payment_method: {
          card: cardElement,
          billing_details: {
            name,
            email,
            phone
          }
        }
      });

      if (result.error) {
        toast.error("Payment failed: " + result.error.message);
        setIsPaying(false);
        return;
      }

      if (result.paymentIntent?.status === "succeeded") {
        toast.success("Payment succeeded! Thank you!");
        onClose(); // close the modal or do something else
      }
    } catch (err) {
      console.error(err);
      toast.error("Unexpected error processing payment");
    } finally {
      setIsPaying(false);
    }
  }

  return (
    <div className="fixed inset-0 z-50 bg-black bg-opacity-50 flex items-center justify-center">
      <div className="relative bg-white text-black w-full max-w-xl mx-4 rounded shadow-lg max-h-[90vh] overflow-y-auto">
        {/* Close button */}
        <button
          onClick={onClose}
          className="absolute top-4 right-4 text-gray-500 hover:text-gray-700 text-2xl"
          aria-label="Close"
        >
          &times;
        </button>

        <div className="p-6">
          <h3 className="text-2xl font-bold text-center mb-6 font-nexa text-red-600">
            Ready to Book Your Event?
          </h3>

          {/* If still creating PaymentIntent => show spinner */}
          {loading && !clientSecret && (
            <p className="text-sm text-gray-600 mb-4">
              Creating your secure payment session...
            </p>
          )}

          {/* Show summary */}
          <div className="mb-6 border bg-gray-50 p-4 rounded shadow-sm">
            <h4 className="text-lg font-nexa font-semibold mb-3 text-red-600">
              Your Catering Details
            </h4>
            <div className="space-y-2 text-sm font-nexa">
              <div className="flex justify-between">
                <span className="font-semibold">Package:</span>
                <span>{selectedPackage.name}</span>
              </div>
              <div className="flex justify-between">
                <span className="font-semibold">Guests:</span>
                <span>{guestCount}</span>
              </div>
              <div className="flex justify-between">
                <span className="font-semibold">Event Date:</span>
                <span>
                  {eventDate
                    ? eventDate.toLocaleDateString?.() || eventDate.toString()
                    : "TBD"}
                </span>
              </div>
              <div className="flex justify-between">
                <span className="font-semibold">Event Type:</span>
                <span>{eventType || "N/A"}</span>
              </div>
              <div className="flex justify-between">
                <span className="font-semibold">Service Hours:</span>
                <span>{serviceHours ?? 0}</span>
              </div>

              <hr className="my-2" />

              <div className="flex justify-between">
                <span className="font-semibold">Name:</span>
                <span>{name}</span>
              </div>
              <div className="flex justify-between">
                <span className="font-semibold">Email:</span>
                <span>{email}</span>
              </div>
              <div className="flex justify-between">
                <span className="font-semibold">Phone:</span>
                <span>{phone}</span>
              </div>

              <hr className="my-2" />

              <div className="flex justify-between">
                <span>Subtotal:</span>
                <span>${subtotal.toFixed(2)}</span>
              </div>
              <div className="flex justify-between">
                <span>Service Fee (20%):</span>
                <span>${serviceFee.toFixed(2)}</span>
              </div>
              <div className="flex justify-between font-semibold">
                <span>Total:</span>
                <span>${total.toFixed(2)}</span>
              </div>
            </div>
          </div>

          {/* Payment Form: single <form> with CardElement + Pay Now */}
          {showCardForm && (
            <form onSubmit={handleSubmitPayment} className="space-y-4">
              <div className="border bg-gray-50 p-4 rounded">
                <h4 className="text-lg font-semibold mb-2 text-red-600">
                  Enter Your Card Details
                </h4>
                <p className="text-sm text-gray-700 mb-3">
                  We accept major credit and debit cards (Visa, MasterCard, Amex).
                </p>
                <div className="p-3 rounded bg-white shadow-md">
                  <CardElement
                    options={{
                      style: {
                        base: {
                          fontSize: "16px",
                          color: "#333",
                          "::placeholder": {
                            color: "#888",
                          },
                        },
                      },
                    }}
                  />
                </div>
                <p className="mt-2 text-xs text-gray-500">
                  Your payment is secure. We do not store any card details.
                </p>
              </div>

              <button
                type="submit"
                disabled={isPaying}
                className="bg-red-600 text-white px-4 py-3 rounded w-full text-lg font-semibold hover:bg-red-700 transition-colors"
              >
                {isPaying ? "Processing..." : "Pay Now"}
              </button>
            </form>
          )}

          {/* If PaymentIntent isn't ready => show nothing or a note */}
          {!showCardForm && !loading && (
            <p className="text-sm text-gray-600">
              Could not create payment session. Please try again or close.
            </p>
          )}
        </div>
      </div>
    </div>
  );
});

export default BookNowModal;