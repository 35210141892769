// CateringHero.tsx
import React from 'react';
import { motion } from 'framer-motion';
import { ReactComponent as CateringTitleText } from "../../assets/images/CateringTitleText.svg";
import { ReactComponent as OfRedzFire } from "../../assets/images/OfRedzFire.svg";
import RightSection from '../Home/HomeHero/RightSection';

const CateringHero: React.FC = () => {
    return (
      <div className=" h-screen p-0 bg-black flex flex-col lg:flex-row items-center justify-center relative">
        {/* LEFT SECTION */}
        <div className="pt-[15vh] flex flex-col items-center w-full lg:w-1/2 h-full text-white relative">
          <motion.div
            className="flex flex-col items-center justify-center w-full h-full text-center px-4"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.3, duration: 0.8 }}
          >
            {/* Title Wrapper */}
            <div className="relative w-[320px] sm:w-[400px] md:w-[500px] mx-auto mb-6">
              {/* The title text SVG (on "top" or "front") */}
              <CateringTitleText className="relative w-full h-auto" />
              
              {/* Skeleton overlaying on top of the title 
                  (or place it behind if you prefer by reversing these two) */}
              <img
                className="absolute top-0 right-0 w-[44%] -translate-y-[60%] -translate-x-[20%] h-auto pointer-events-none"
                style={{ zIndex: 10 }} 
                src='https://redz-bucket.s3.amazonaws.com/CateringSkeleton.svg'
                alt='Catering Skeleton'
              />
            </div>
  
            {/* The small "OfRedzFire" SVG below the title */}
            <OfRedzFire className="w-64 h-auto mb-6" />
  
            {/* Catering description text */}
            <p className="font-myriad text-base md:text-lg lg:text-xl text-redz-white max-w-2xl pt-12">
              Whether you're hosting an intimate gathering or a grand celebration,
              Redz offers exceptional catering services that will impress your
              guests and make your event unforgettable. Our culinary expertise,
              combined with our passion for food, ensures that every dish is
              crafted to perfection, providing your guests with a dining
              experience they'll remember.
            </p>
          </motion.div>
        </div>

        <RightSection />
       
      </div>
    );
  };
  

export default CateringHero;