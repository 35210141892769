// PricingSummary.tsx
import React from 'react';
import { observer } from 'mobx-react-lite';
import cateringStore from '../../../stores/CateringStore';

const PricingSummary: React.FC = observer(() => {
  const {
    selectedPackage,
    guestCount,
    billableGuests,
    basePricePerPerson,
    addOnsTotalPerPerson,
    subtotal,
    serviceFee,
    total,
  } = cateringStore;

  if (!selectedPackage) {
    return <p className="text-red-500">No package selected.</p>;
  }

  return (
    <div className="my-8">
      <h2 className="text-2xl font-nexa mb-4">4) Pricing Summary</h2>

      {guestCount < 40 && (
        <p className="text-sm text-gray-600 mb-2">
          You entered <strong>{guestCount}</strong> guests.
          Billing for <strong>40</strong> guests minimum.
        </p>
      )}

      <div className="space-y-2 text-base font-myriad">
        {/* If package has upgradeLevels => reflect that as “Chosen Tier / Default” */}
        {selectedPackage.upgradeLevels ? (
          <p>
            <strong>Chosen / Default Tier Price Per Person:</strong> $
            {basePricePerPerson.toFixed(2)}
          </p>
        ) : (
          <p>
            <strong>Base Price Per Person:</strong> $
            {basePricePerPerson.toFixed(2)}
          </p>
        )}

        <p>
          <strong>Add-Ons Per Person:</strong> $
          {addOnsTotalPerPerson.toFixed(2)}
        </p>
        <p>
          <strong>Subtotal (x {billableGuests} guests):</strong> $
          {subtotal.toFixed(2)}
        </p>
        <p>
          <strong>Service Fee (20%):</strong> ${serviceFee.toFixed(2)}
        </p>
      </div>

      <p className="text-xl font-bold mt-3">
        Grand Total: ${total.toFixed(2)}
      </p>
    </div>
  );
});

export default PricingSummary;