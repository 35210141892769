export const entreeMap: Record<string, string> = {
    'Tostada Bowl': 'Entrees',
    'Keto Tacos': 'Entrees',
    'Birria Plate': 'Entrees',
    'Pizzadilla': 'Entrees',
    'Ramen': 'Entrees',
    'Taquitos De Papa': 'Entrees',
    'Lunch Specials': 'Entrees',
    'Nachos': 'Entrees',
    'Mulita': 'Entrees',
    'Quesadilla': 'Entrees',
    'Street Taco': 'Entrees',
    'Yona Loaded Fries': 'Entrees',
    'Burrito': 'Entrees',
    'Quesotacos': 'Entrees',
    'Street Taco Plate': 'Entrees',
    'Vampiro': 'Entrees',
    'Yona Deluxe': 'Entrees',
    'Yona Special': 'Entrees',
}

// entreeSubcatMap.ts

export const ENTREE_SUBCAT_MAP: Record<string, "Local Favorites" | "Specialties" | "Single Items"> = {
    // Local Favorites
    "Street Taco": "Local Favorites",
    "Quesotacos": "Local Favorites",
    "Yona Special": "Local Favorites",
  
    // Specialties
    "Yona Deluxe": "Specialties",
    "Street Taco Plate": "Specialties",
    "Birria Plate": "Specialties",
    "Pizzadilla": "Specialties",
    "Sampler Platter": "Specialties",         // if you want
    "Quesotaco Platter": "Specialties",       // if you want
  
    // Single Items
    "Vampiro": "Single Items",
    "Mulita": "Single Items",
    "Burrito": "Single Items",
    "Nachos": "Single Items",
    "Quesadilla": "Single Items",
    "Yona Loaded Fries": "Single Items",
    "Birria Ramen": "Single Items",
    "Tostada Bowl": "Single Items",
    "Taquitos De Papa": "Single Items",
  };

// example: categoryGroups.ts (or inline)
export const categoryGroupMap: Record<string, string> = {
    // All these original Clover category names become "Entrees"
    '805 Sweets Candy': 'Dessert',
    "Beverages (Non-Alcohol)": "Beverages",
    ...entreeMap
};