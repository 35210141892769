import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import PaymentSummary from './PaymentSummary';
import { toast } from 'react-toastify';
import { cartStore } from '@redz/shared';
import ContactInfoFields from './ContactInfoFields';
import { useNavigate } from 'react-router-dom';
import { isOpenForBusinessLocal } from './isOpenForBusinessLocal';

interface PaymentSectionProps {
  // Optional: show contact fields (for pickup scenario)
  showContactFields?: boolean;

  // If you're collecting these in PaymentSection, pass them in:
  name?: string;
  email?: string;
  phone?: string;
  marketingOptIn?: boolean;

  // If PaymentSection also needs to update them, pass setters:
  setName?: (val: string) => void;
  setEmail?: (val: string) => void;
  setPhone?: (val: string) => void;
  setMarketingOptIn?: (val: boolean) => void;
}

const PaymentSection: React.FC<PaymentSectionProps> = observer((props) => {
  const {
    showContactFields,
    name,
    email,
    phone,
    marketingOptIn,
    setName,
    setEmail,
    setPhone,
    setMarketingOptIn,
  } = props;

  const {
    deliveryType,
    checkoutCartWithInfo,
    clearCart,
    validatePromoCode,
    fetchLoyaltyPointsByPhone
  } = cartStore;

  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);

  // Promo code input
  const [promoInput, setPromoInput] = useState("");
  const [isOpenLocal, setIsOpenLocal] = useState(true);

  // 1) On mount, check if open
  useEffect(() => {
    setIsOpenLocal(isOpenForBusinessLocal());
  }, []);

  // 2) Example: re-check every minute if you want
  //    so the button “locks” if it hits 9 PM, etc.
  useEffect(() => {
    const interval = setInterval(() => {
      setIsOpenLocal(isOpenForBusinessLocal());
    }, 60 * 1000);
    return () => clearInterval(interval);
  }, []);
  
  useEffect(() => {
    // Sanitize phone before fetching loyalty
    const digits = phone?.replace(/\D+/g, "") || "";
    if (digits.length >= 10) {
      fetchLoyaltyPointsByPhone(digits);
    }
  }, [phone, fetchLoyaltyPointsByPhone]);

  async function handleApplyPromo() {
    const phoneDigits = phone?.replace(/\D+/g, "") || "";
    const data = await validatePromoCode(promoInput, phoneDigits);
    if (data) {
      if (data.success) {
        data.message
          ? toast.success(data.message)
          : toast.success("Promo Code successfully applied.");
      } else {
        data.message
          ? toast.error(data.message)
          : toast.error("Promo Code invalid.");
      }
    } else {
      toast.error("Something went wrong. Please try again later.");
    }
  }

  async function handlePayNow() {
    if (!stripe || !elements) return;
    setLoading(true);

    // 4) If we see locally that we’re closed, block
    if (!isOpenLocal) {
      toast.error("We’re currently closed. Please order during normal business hours.");
      return;
    }

    // If "delivery" but no quote yet
    if (deliveryType === "delivery" && !cartStore.deliveryQuoteId) {
      toast.error("Please select a valid address and get a delivery estimate first.");
      setLoading(false);
      return;
    }

    // Sanitize phone
    const phoneDigits = (phone || "").replace(/\D+/g, "");
    if (phoneDigits.length < 10) {
      toast.error("Please enter a valid 10-digit phone number.");
      setLoading(false);
      return;
    }

    try {
      if (name && email && phoneDigits && marketingOptIn !== undefined) {
        // 1) create order in Django
        const { order_id, client_secret } = await checkoutCartWithInfo({
          name,
          email,
          phone: phoneDigits,
          marketingOptIn,
        });

        // 2) confirm card on Stripe
        const cardElement = elements.getElement(CardElement);
        if (!cardElement) {
          toast.error("No card element found");
          setLoading(false);
          return;
        }

        const result = await stripe.confirmCardPayment(client_secret, {
          payment_method: {
            card: cardElement,
            billing_details: { name, email, phone: phoneDigits },
          },
        });

        if (result.error) {
          toast.error("Stripe payment error: " + result.error.message);
          setLoading(false);
          return;
        }
        if (result.paymentIntent?.status === "succeeded") {
          toast.success("Payment succeeded! Thank you!");
          clearCart();
          navigate('/congratulations');
        }
      }
    } catch (err) {
      console.error("Checkout error:", err);
      toast.error("Unexpected checkout error");
    } finally {
      setLoading(false);
    }
  }

  // -------------------------------------------------------------------------
  // RENDER
  // -------------------------------------------------------------------------
  return (
    <div>
      <h3 className="text-xl font-bold text-center mb-4">
        Ready to Place Your Order?
      </h3>

      {/* If showContactFields is true, gather pickup contact info */}
      {showContactFields && (
        <ContactInfoFields
          name={name || ""}
          setName={(val) => setName?.(val)}
          email={email || ""}
          setEmail={(val) => setEmail?.(val)}
          phone={phone || ""}
          setPhone={(val) => setPhone?.(val)}
          marketingOptIn={marketingOptIn || false}
          setMarketingOptIn={(b) => setMarketingOptIn?.(b)}
          heading="Pickup Contact Info"
        />
      )}

      {/* 1) Payment Summary first -> Let the user see the total they're about to pay */}
      <div className="mb-4">
        <PaymentSummary />
      </div>

      {/* 2) Promo Code Entry -> Let them try a code before entering card details */}
      <div className="mb-6">
        <label className="block text-sm font-semibold mb-1">Have a Promo Code?</label>
        <div className="flex">
          <input
            type="text"
            value={promoInput}
            onChange={(e) => setPromoInput(e.target.value)}
            className="border p-2 w-full rounded-l"
            placeholder="e.g. SAVE10"
          />
          <button
            onClick={handleApplyPromo}
            className="bg-red-600 text-white px-4 py-2 rounded-r"
          >
            Apply
          </button>
        </div>
      </div>

      {/* 3) Card Details -> users are excited after seeing total & maybe discount */}
      <div className="mb-4 border bg-gray-50 p-4 rounded">
        <h4 className="text-lg font-semibold mb-2 text-red-600">Enter Your Card Details</h4>
        <p className="text-sm text-gray-700 mb-3">
          We accept major credit and debit cards (Visa, MasterCard, Amex).
        </p>
        <div className="p-3 rounded bg-white shadow-md">
          <CardElement
            options={{
              style: {
                base: {
                  fontSize: "16px",
                  color: "#333",
                  "::placeholder": {
                    color: "#888",
                  },
                },
              },
            }}
          />
        </div>
        <p className="mt-2 text-xs text-gray-500">
          Your payment is secure. We do not store any card details.
        </p>
      </div>

      {/* 4. Sticky (fixed) bottom section: Pay Now Button */}
      <div className="sticky -bottom-6 z-10 bg-white pt-4 px-4 border-t">
        <button
          onClick={handlePayNow}
          disabled={loading}
          className="bg-red-600 text-white px-4 py-3 rounded w-full text-lg font-semibold hover:bg-red-700 transition-colors"
        >
          {loading ? 'Processing...' : 'Pay Now'}
        </button>
        {!isOpenLocal && (
          <p className="text-center text-red-600 mt-2 text-sm font-bold">
            We’re currently closed. Online orders are disabled.
          </p>
        )}
      </div>
    </div>
  );
});

export default PaymentSection;