import React, { useRef } from "react";
import OrderHero from "./OrderHero";
import MenuPage from "./MenuPage";

const Order: React.FC = () => {
  // Create a ref to the MenuPage section
  const menuRef = useRef<HTMLDivElement | null>(null);

  function handleScrollToMenu() {
    if (menuRef.current) {
      // Calculate the offset from the top of the viewport
      const elementTop = menuRef.current.getBoundingClientRect().top;
      // Convert 15vh to a pixel offset
      const headerOffset = window.innerHeight * 0.15;
  
      // The pageYOffset adds the current scroll position
      const scrollPosition =
        elementTop + window.pageYOffset - headerOffset;
  
      window.scrollTo({
        top: scrollPosition,
        behavior: 'smooth',
      });
    }
  }

  return (
    <div className="bg-redz-white">
      {/* 1) The Hero */}
      <OrderHero onScrollToMenu={handleScrollToMenu} />

      <section className="py-10 px-4">
        <div className="max-w-6xl mx-auto flex flex-col md:flex-row items-center md:items-start md:justify-center">
            
            {/* LEFT COLUMN: Map */}
            <div className="w-full md:w-1/2 flex justify-center md:justify-end mb-6 md:mb-0 md:mr-8">
            <div className="w-full max-w-md h-0 pb-[56.25%] relative overflow-hidden shadow-lg rounded-md">
                <iframe
                title="Yona Redz Map"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d6582.193667184972!2d-119.70788402341732!3d34.42429537301909!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80e915447d06cb7f%3A0xb2d8d13b5897954e!2sYona%20Redz!5e0!3m2!1sen!2sus!4v1739463224259!5m2!1sen!2sus"
                style={{ border: 0 }}
                loading="lazy"
                allowFullScreen
                referrerPolicy="no-referrer-when-downgrade"
                className="absolute top-0 left-0 w-full h-full"
                />
            </div>
            </div>

            {/* RIGHT COLUMN: Heading & Text */}
            <div className="w-full md:w-1/2 text-center md:text-left">
            <h2 className="text-3xl font-bold mb-4 text-redz-red font-myriad leading-tight">
                Ordering from <br/>
                <span className="font-nexa">
                Yona Redz <span className="font-myriad">at</span> 1230 State Street
                </span>
            </h2>
            <p className="text-gray-700 font-redsox text-2xl text-balance">
                Our flagship location in Santa Barbara, serving authentic flavors
                with a modern twist.
            </p>
            </div>

            </div>
        </section>

      {/* 3) The actual Menu section */}
      <div ref={menuRef}>
        <MenuPage />
      </div>
    </div>
  );
};

export default Order;