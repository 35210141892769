// AddOnsSelector.tsx
import React from 'react';
import { observer } from 'mobx-react-lite';
import cateringStore from '../../../stores/CateringStore';

const AddOnsSelector: React.FC = observer(() => {
  const { addons, selectedAddOns, toggleAddOn } = cateringStore;

  return (
    <div className="my-8">
      <h2 className="text-2xl font-nexa mb-4">3) Select Add-Ons</h2>
      <div className="grid gap-3 sm:grid-cols-2 lg:grid-cols-3">
        {addons.map((addOn) => (
          <label
            key={addOn.slug_id}
            className={`
              flex items-center bg-white border border-gray-300 p-3 rounded
              transition hover:border-redz-red
              ${selectedAddOns[addOn.slug_id] ? 'border-redz-red' : ''}
            `}
          >
            <input
              type="checkbox"
              checked={!!selectedAddOns[addOn.slug_id]}
              onChange={() => toggleAddOn(addOn.slug_id)}
              className="mr-3"
            />
            <div>
              <span className="font-redsox text-lg">{addOn.label}</span>
              <p className="text-sm text-gray-600 ml-1">
                +${Number(addOn.pricePerPerson).toFixed(2)}/person
              </p>
            </div>
          </label>
        ))}
      </div>
    </div>
  );
});

export default AddOnsSelector;