// GuestCountInput.tsx
import React from 'react';
import { observer } from 'mobx-react-lite';
import cateringStore from '../../../stores/CateringStore';

const GuestCountInput: React.FC = observer(() => {
  const { guestCount, setGuestCount } = cateringStore;

  return (
    <div className="my-8">
      <h2 className="text-2xl font-nexa mb-4">2) Number of Guests</h2>
      <p className="text-sm mb-1">
        Our packages typically require a <strong>40-person minimum</strong>. 
        If you have fewer than 40 guests, a surcharge applies to meet the 40-person price.
      </p>
      <div className="flex items-center space-x-3">
        <input
          type="number"
          value={guestCount}
          onChange={(e) => {
            const val = parseInt(e.target.value, 10);
            setGuestCount(val);
          }}
          className="w-24 p-2 border rounded font-myriad text-center"
        />
        {guestCount < 40 && (
          <span className="text-sm text-gray-600 bg-gray-200 p-2 rounded">
            You will be charged for 40 guests total.
          </span>
        )}
      </div>
    </div>
  );
});

export default GuestCountInput;