import React from "react";
import { motion } from "framer-motion";
import { ReactComponent as WelcomeSeperator } from '../../../assets/images/WelcomeSeperator.svg';
import { ReactComponent as ToRedz } from "../../../assets/images/ToRedz.svg";
import TacoAccent from "../../common/TacoAccent";

interface WelcomeProps{
    home?: boolean;
}

export const Welcome: React.FC<WelcomeProps> = ({home}) => (
    <h1 className="inline-block relative text-5xl md:text-6xl lg:text-7xl mb-4 font-nexa text-center text-red-500 ">
        <TacoAccent className="absolute -top-5 left-[-20px] md:left-[-40px] w-8 md:w-12 h-8 md:h-12" />
            WELCOME
        <TacoAccent className="absolute -top-5 right-[-20px] md:right-[-40px] w-8 md:w-12 h-8 md:h-12 rotate-[120deg]" />
        {!home? <ToRedz className="relative w-3/4 -right-44 -top-4" />: <></>}
    </h1>
)

const WelcomeSection: React.FC = () => (
    <motion.div
        className="relative mb-4 mt-4"
        initial={{ opacity: 0, scale: 0 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 1 }}
    >
        <Welcome home={true}/>
        <WelcomeSeperator className="w-full items-center justify-center" />

    </motion.div>
    
);

export default WelcomeSection;