import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { cloverMenuStore } from '@redz/shared';
import { cartStore } from '@redz/shared';
import type { MenuItem, Modifier } from '@redz/shared';

import { categoryGroupMap, ENTREE_SUBCAT_MAP } from './categoryGroups';
import { cleanupName } from './cleanupName';
import { parseBaseAndProteinWithCategoryCheck } from './parseBaseAndProtein';

import VariantAndModifierModal from './VariantAndModifierModal';
import CartSidebar from './CartSidebar';

const MenuPage: React.FC = observer(() => {
  const { items, loading, error, fetchCloverMenu } = cloverMenuStore;

  const [lastAddedLineItemId, setLastAddedLineItemId] = useState<string | null>(null);

  // For the variant+modifier modal
  const [showCombinedModal, setShowCombinedModal] = useState(false);
  const [combinedTitle, setCombinedTitle] = useState('');
  const [combinedItems, setCombinedItems] = useState<MenuItem[]>([]);

  // Fetch on mount
  useEffect(() => {
    fetchCloverMenu();
  }, [fetchCloverMenu]);

  if (loading) {
    return <p className="p-4 text-redz-red">Loading Clover Menu...</p>;
  }
  if (error) {
    return <p className="p-4 text-red-600">{error}</p>;
  }

  // -------------------------------------------------------
  // 1) Group items by top-level category (Entrees, Dessert, etc.)
  // -------------------------------------------------------
  const groupedByHighLevel: Record<string, MenuItem[]> = {};

  items.forEach((item) => {
    const originalCat = item.categoryName ?? 'Misc';
    let groupName = categoryGroupMap[originalCat] ?? originalCat;

    // -- FORCE "CHEESE QUESADILLA" into Kids Menu --
    const lowerName = item.name.toLowerCase().trim();
    if (lowerName.includes('cheese') && lowerName.includes('quesadilla')) {
      // override group to "Kids Menu"
      groupName = 'Kids Menu';
    }

    const cleanedName = cleanupName(item.name, originalCat);

    if (!groupedByHighLevel[groupName]) {
      groupedByHighLevel[groupName] = [];
    }
    groupedByHighLevel[groupName].push({
      ...item,
      name: cleanedName
    });
  });

  // We define an order for these categories
  const HIGH_LEVEL_ORDER = [
    'Entrees',
    'Family Meals',
    'Sides',
    'Beverages',
    'Dessert',
    'Kids Menu',
  ];

  const groupNames = Object.keys(groupedByHighLevel).sort((a, b) => {
    const aIndex = HIGH_LEVEL_ORDER.indexOf(a);
    const bIndex = HIGH_LEVEL_ORDER.indexOf(b);
    if (aIndex !== -1 && bIndex !== -1) return aIndex - bIndex;
    if (aIndex !== -1) return -1;
    if (bIndex !== -1) return 1;
    return a.localeCompare(b);
  });

  // -------------------------------------------------------
  // 2) Helper to group items by baseName
  // -------------------------------------------------------
  function buildGroupedByBaseName(itemsInGroup: MenuItem[]) {
    const grouped: Record<string, MenuItem[]> = {};
    for (const it of itemsInGroup) {
      const { baseName } = parseBaseAndProteinWithCategoryCheck(
        it.name,
        it.categoryName ?? ''
      );
      const cleanedBase = cleanupName(baseName, it.categoryName ?? '');
      if (!grouped[cleanedBase]) {
        grouped[cleanedBase] = [];
      }
      grouped[cleanedBase].push(it);
    }
    return grouped;
  }

  // -------------------------------------------------------
  // 3) Single or multi-variant logic
  // -------------------------------------------------------
  function handleSingleItemClick(itemId: number) {
    const found = items.find((i) => i.id === itemId);
    if (!found) return;

    // If no modifiers => add directly
    if (!found.modifier_groups || found.modifier_groups.length === 0) {
      const lineId = cartStore.addToCart(found, 1, []);
      setLastAddedLineItemId(lineId);
      cartStore.openSidebar();
      return;
    }

    setCombinedTitle(`Customize: ${found.name}`);
    setCombinedItems([found]);
    setShowCombinedModal(true);
  }

  function handleGroupClick(baseName: string, groupItems: MenuItem[]) {
    if (groupItems.length > 1) {
      setCombinedTitle(`Customize: ${baseName}`);
      setCombinedItems(groupItems);
      setShowCombinedModal(true);
    } else if (groupItems.length === 1) {
      const single = groupItems[0];
      if (!single.modifier_groups || single.modifier_groups.length === 0) {
        const lineId = cartStore.addToCart(single, 1, []);
        setLastAddedLineItemId(lineId);
        cartStore.openSidebar();
      } else {
        setCombinedTitle(`Customize: ${single.name}`);
        setCombinedItems([single]);
        setShowCombinedModal(true);
      }
    }
  }

  function handleCombinedConfirm(variantId: number | null, selectedMods: Modifier[]) {
    if (!variantId) {
      setShowCombinedModal(false);
      return;
    }
    const found = items.find((i) => i.id === variantId);
    if (!found) {
      setShowCombinedModal(false);
      return;
    }
    const lineId = cartStore.addToCart(found, 1, selectedMods);
    setLastAddedLineItemId(lineId);
    cartStore.openSidebar();
    setShowCombinedModal(false);
  }

  // -------------------------------------------------------
  // 4) Rendering: function to render a grid of items
  //    (inlined so we don't define separate components)
  // -------------------------------------------------------
  function renderItemGrid(itemsForGrid: MenuItem[]) {
    // 4A) Group by baseName => single vs. multi-variant
    const grouped = buildGroupedByBaseName(itemsForGrid);

    return (
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-8">
        {Object.entries(grouped).map(([cleanBaseName, itemVariants]) => {
          if (itemVariants.length === 1) {
            // Single item
            const solo = itemVariants[0];
            return (
              <div
                key={solo.id}
                className="bg-white rounded-lg shadow p-6 hover:shadow-md cursor-pointer transition-shadow"
                onClick={() => handleSingleItemClick(solo.id)}
              >
                {/* If item has photo */}
                {solo.photo && (
                  <img
                    src={solo.photo}
                    alt={solo.name}
                    className="w-full h-40 object-cover mb-3 rounded"
                  />
                )}

                <h4 className="text-lg font-bold mb-2 text-redz-red">
                  {solo.name}
                </h4>
                {solo.description && (
                  <p className="text-xs text-gray-700 mb-3 leading-relaxed font-myriad">
                    {solo.description}
                  </p>
                )}
                {solo.base_price != null && (
                  <p className="text-xs text-gray-800 font-medium">
                    ${solo.base_price.toFixed(2)}
                  </p>
                )}
              </div>
            );
          } else {
            // Multi-variant
            const sortedByPrice = [...itemVariants].sort((a, b) => {
              const aPrice = a.base_price ?? Infinity;
              const bPrice = b.base_price ?? Infinity;
              return aPrice - bPrice;
            });
            const cheapest = sortedByPrice[0];

            let displayPrice = '';
            if (cheapest.base_price !== null && cheapest.base_price !== undefined) {
              displayPrice = `$${cheapest.base_price.toFixed(2)}`;
            }

            let groupDescription = cheapest.description?.trim() || '';
            if (!groupDescription) {
              const anyDesc = sortedByPrice.find(
                (iv) => iv.description && iv.description.trim().length > 0
              );
              if (anyDesc) groupDescription = anyDesc.description || '';
            }

            let groupPhoto = cheapest.photo?.trim() || '';
            if (!groupPhoto) {
              const anyPhoto = sortedByPrice.find(
                (iv) => iv.photo && iv.photo.trim().length > 0
              );
              if (anyPhoto) groupPhoto = anyPhoto.photo || '';
            }

            return (
              <div
                key={cleanBaseName}
                className="bg-white rounded-lg shadow p-6 hover:shadow-md cursor-pointer transition-shadow"
                onClick={() => handleGroupClick(cleanBaseName, itemVariants)}
              >
                {/* If item has photo */}
                {groupPhoto && (
                  <img
                    src={groupPhoto}
                    alt={cheapest.categoryName}
                    className="w-full h-40 object-cover mb-3 rounded"
                  />
                )}
                <h5 className="text-md font-bold mb-2 text-redz-red">
                  {cleanBaseName}
                </h5>
                {groupDescription && (
                  <p className="text-xs text-gray-700 mb-3 leading-relaxed font-myriad">
                    {groupDescription}
                  </p>
                )}
                {displayPrice && (
                  <p className="text-xs text-gray-800 font-medium">
                    {`${displayPrice}`}
                  </p>
                )}
              </div>
            );
          }
        })}
      </div>
    );
  }

  // -------------------------------------------------------
  // 5) Finally, loop through top-level categories
  // -------------------------------------------------------

  return (
    <div className="min-h-screen bg-redz-white px-[4vw] pb-8 font-nexa text-redz-red">

    {
    Object.keys(groupedByHighLevel)
      .sort((a, b) => {
        const aIndex = HIGH_LEVEL_ORDER.indexOf(a);
        const bIndex = HIGH_LEVEL_ORDER.indexOf(b);
        if (aIndex !== -1 && bIndex !== -1) return aIndex - bIndex;
        if (aIndex !== -1) return -1;
        if (bIndex !== -1) return 1;
        return a.localeCompare(b);
      })
      .map((groupName) => {
        const groupItems = groupedByHighLevel[groupName];
        if (!groupItems || groupItems.length === 0) return null;

        // If this category is "Entrees," we do the sub-split
        if (groupName === 'Entrees') {
          const localFav: MenuItem[] = [];
          const specialties: MenuItem[] = [];
          const singleItems: MenuItem[] = [];

          // Distribute each item into one of the subcats
          groupItems.forEach((it) => {
            const subcat = ENTREE_SUBCAT_MAP[it.categoryName?? ''] || 'Single Items';
            if (subcat === 'Local Favorites') localFav.push(it);
            else if (subcat === 'Specialties') specialties.push(it);
            else singleItems.push(it);
          });

          return (
            <div key="Entrees" className="mb-10">
              <h3 className="text-2xl font-bold mb-4">Entrees</h3>

              {/* LOCAL FAVORITES */}
              {localFav.length > 0 && (
                <div className="mb-8">
                  <h4 className="text-lg font-semibold mb-3 text-gray-800">
                    Local Favorites
                  </h4>
                  {renderItemGrid(localFav)}
                </div>
              )}

              {/* SPECIALTIES */}
              {specialties.length > 0 && (
                <div className="mb-8">
                  <h4 className="text-lg font-semibold mb-3 text-gray-800">
                    Specialties
                  </h4>
                  {renderItemGrid(specialties)}
                </div>
              )}

              {/* SINGLE ITEMS */}
              {singleItems.length > 0 && (
                <div>
                  <h4 className="text-lg font-semibold mb-3 text-gray-800">
                    Single Items
                  </h4>
                  {renderItemGrid(singleItems)}
                </div>
              )}
            </div>
          );
        } else {
          // For any other top-level category, just render
          return (
            <div key={groupName} className="mb-10">
              <h3 className="text-2xl font-bold mb-4">{groupName}</h3>
              {renderItemGrid(groupItems)}
            </div>
          );
        }
      })
    }

      {/* If the cart is open */}
      {cartStore.isSidebarOpen && (
        <div className="fixed inset-0 z-50 flex">
          {/* Overlay */}
          <div
            className="absolute inset-0 bg-black bg-opacity-50"
            onClick={() => cartStore.closeSidebar()}
          />
          <CartSidebar
            isOpen={cartStore.isSidebarOpen}
            onClose={() => cartStore.closeSidebar()}
            highlightLineItemId={lastAddedLineItemId}
          />
        </div>
      )}

      {/* Variant+Modifier modal */}
      {showCombinedModal && combinedItems.length > 0 && (
        <VariantAndModifierModal
          baseTitle={combinedTitle}
          variantItems={combinedItems}
          onClose={() => setShowCombinedModal(false)}
          onConfirm={handleCombinedConfirm}
        />
      )}
    </div>
  );
});

export default MenuPage;