import React from 'react';
import { observer } from 'mobx-react-lite';
import menuStore from '../../stores/MenuStore';
import { ReactComponent as DollarSign } from '../../assets/images/DollarSignWhite.svg';
import { ReactComponent as TwentyOneSvg } from '../../assets/images/21Plus.svg';
import SectionTitle from './SectionTitle';

// Helper: format numeric or string price
function formatPrice(price?: number): string | null {
  if (price == null) return null;
  return price.toFixed(2);
}

const SidesDrinksSection: React.FC = observer(() => {
  const { sidesItems, drinksItems } = menuStore;

  // Filter or group "Side of Meat" items
  const sideOfMeatItems = sidesItems.filter(item =>
    item.name.startsWith('Side of Meat:')
  );
  const nonSideOfMeatItems = sidesItems.filter(item =>
    !item.name.startsWith('Side of Meat:')
  );

  // Row component for standard sides
  const StandardRowItem = ({ item }: { item: any }) => {
    const priceString = formatPrice(item.base_price);
    return (
      <div className="mb-4">
        <div className="flex items-center justify-between">
          <h4 className="text-redz-red font-redsox text-5xl">{item.name}</h4>
          {priceString && (
            <div className="flex items-baseline text-redz-white text-xl font-nexa font-bold">
              <DollarSign className="w-4 h-4 mr-1 -translate-y-1/3 translate-x-1/4" />
              {priceString}
            </div>
          )}
        </div>
        {item.description && item.description.trim() !== '' && (
          <p className="text-redz-white text-lg font-bold mt-1 ml-2">
            {item.description}
          </p>
        )}
      </div>
    );
  };

  // Block for "Side of Meat"
  const SideOfMeatBlock = () => {
    if (sideOfMeatItems.length === 0) return null;
    const parseMeatName = (fullName: string) => fullName.replace('Side of Meat: ', '');
    return (
      <div className="mb-6">
        <h4 className="text-redz-red font-redsox text-5xl">Side of Meat</h4>
        {sideOfMeatItems.map(meatItem => {
          const priceString = formatPrice(meatItem.base_price);
          return (
            <div
              className="flex items-center justify-between ml-4 mt-2"
              key={meatItem.id}
            >
              <span className="text-redz-white text-lg font-bold">
                {parseMeatName(meatItem.name)}
              </span>
              {priceString && (
                <div className="flex items-baseline text-redz-white text-xl font-nexa font-bold">
                  <DollarSign className="w-3 h-3 mr-1 -translate-y-1/3 translate-x-1/4" />
                  {priceString}
                </div>
              )}
            </div>
          );
        })}
      </div>
    );
  };

  // Drinks: filter into Non-Alc vs. Alcoholic via subcategoryName
  const nonAlcDrinks = drinksItems.filter(
    item => item.subcategoryName === 'Non-Alcoholic'
  );
  const alcDrinks = drinksItems.filter(
    item => item.subcategoryName === 'Alcoholic'
  );

  const DrinksRowItem = ({ item }: { item: any }) => {
    const priceString = formatPrice(item.base_price);
    return (
      <div className="mb-4">
        <div className="flex items-center justify-between">
          <h4 className="text-redz-red font-redsox text-5xl">{item.name}</h4>
          {priceString && (
            <div className="flex items-baseline text-redz-white text-xl font-nexa font-bold">
              <DollarSign className="w-4 h-4 mr-1 -translate-y-1/3 translate-x-1/4" />
              {priceString}
            </div>
          )}
        </div>
        {item.description && item.description.trim() !== '' && (
          <p className="text-redz-white text-lg font-bold mt-1 ml-2">
            {item.description}
          </p>
        )}
      </div>
    );
  };

  return (
    <section className="py-8 px-4">
      <div className="max-w-5xl mx-auto grid grid-cols-1 md:grid-cols-2 gap-48">
        
        {/* Left column for Sides */}
        <div id='sides'>
          <SectionTitle title="Sides" alternate />
          {nonSideOfMeatItems.map(item => (
            <StandardRowItem key={item.id} item={item} />
          ))}
          <SideOfMeatBlock />
        </div>

        {/* Right column for Drinks */}
        <div className="relative" id='drinks'>
          <SectionTitle title="Drinks" alternate />

          {/* Non-Alcoholic */}
          {nonAlcDrinks.map(item => (
            <DrinksRowItem key={item.id} item={item} />
          ))}

          {/* 21+ divider */}
          <div className="flex items-start my-8 left-0 md:-left-36 relative">
            <TwentyOneSvg className="w-1/2 md:w-3/4" />
          </div>

          {/* Alcoholic */}
          {alcDrinks.map(item => (
            <DrinksRowItem key={item.id} item={item} />
          ))}
        </div>
      </div>
    </section>
  );
});

export default SidesDrinksSection;