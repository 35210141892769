"use strict";
/// <reference types="@types/googlemaps" />
Object.defineProperty(exports, "__esModule", { value: true });
exports.getNextThresholdMessage = exports.parseAddressComponents = exports.getDistanceMiles = exports.getNextTierDiscountInfo = exports.getRestaurantDeliveryDiscount = void 0;
/**
 * Returns how much the RESTAURANT is willing to discount on delivery,
 * based on the user’s cart subtotal alone. This is not the final cost from Uber.
 * For example:
 *   <20 => discount=0
 *   [20..40) => 2.99
 *   [40..60) => 5.99
 *   [60..75) => 8.99
 *   >=75 => Infinity (full coverage)
 */
function getRestaurantDeliveryDiscount(subtotal) {
    if (subtotal >= 75) {
        return Number.POSITIVE_INFINITY;
    }
    else if (subtotal >= 60) {
        return 8.99;
    }
    else if (subtotal >= 40) {
        return 5.99;
    }
    else if (subtotal >= 20) {
        return 2.99;
    }
    return 0;
}
exports.getRestaurantDeliveryDiscount = getRestaurantDeliveryDiscount;
/**
 * Figures out the “next tier” above the current subtotal that would
 * *increase* the restaurant’s discount. E.g. if user is at 19.22,
 * next tier is 20 => discount goes from 0 to 2.99 => they can “save 2.99 more”.
 * We do *not* say “the final cost is from X to Y,” because Uber's final cost is unknown.
 */
function getNextTierDiscountInfo(subtotal) {
    // Our tiers in ascending order
    const tiers = [
        { threshold: 20, discount: 2.99 },
        { threshold: 40, discount: 5.99 },
        { threshold: 60, discount: 8.99 },
        { threshold: 75, discount: Number.POSITIVE_INFINITY }, // fully covered
    ];
    const currentDiscount = getRestaurantDeliveryDiscount(subtotal);
    // find the next threshold that’s strictly > subtotal
    const next = tiers.find(t => t.threshold > subtotal);
    if (!next) {
        // already above 75 => no further tier
        return null;
    }
    const needed = next.threshold - subtotal;
    const extraSavings = (next.discount === Number.POSITIVE_INFINITY)
        ? Number.POSITIVE_INFINITY
        : (next.discount - currentDiscount);
    // if the next discount is not bigger => no reason to show
    if (extraSavings <= 0) {
        return null;
    }
    return {
        needed,
        nextDiscount: next.discount,
        currentDiscount,
        extraSavings, // how many extra dollars they'd save at next tier
    };
}
exports.getNextTierDiscountInfo = getNextTierDiscountInfo;
// Haversine formula: distance in miles
function getDistanceMiles(lat1, lon1, lat2, lon2) {
    if (!lat2 || !lon2)
        return 0;
    const R = 3958.8; // Earth radius in miles
    const toRad = (val) => (val * Math.PI) / 180;
    const dLat = toRad(lat2 - lat1);
    const dLon = toRad(lon2 - lon1);
    const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(toRad(lat1)) *
            Math.cos(toRad(lat2)) *
            Math.sin(dLon / 2) *
            Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    return R * c;
}
exports.getDistanceMiles = getDistanceMiles;
// Parse Google's address components into structured fields
function parseAddressComponents(components) {
    let streetNumber = "";
    let route = "";
    let city = "";
    let state = "";
    let zip = "";
    let country = "";
    components.forEach((comp) => {
        const types = comp.types;
        if (types.includes("street_number")) {
            streetNumber = comp.long_name;
        }
        if (types.includes("route")) {
            route = comp.long_name;
        }
        if (types.includes("locality")) {
            city = comp.long_name;
        }
        if (types.includes("administrative_area_level_1")) {
            state = comp.short_name;
        }
        if (types.includes("postal_code")) {
            zip = comp.long_name;
        }
        if (types.includes("country")) {
            country = comp.short_name;
        }
    });
    return {
        street: `${streetNumber} ${route}`.trim(),
        city,
        state,
        zip,
        country
    };
}
exports.parseAddressComponents = parseAddressComponents;
/**
 * Show a tip to user: how much more they need to add to get the next discount threshold
 */
function getNextThresholdMessage(subtotal) {
    if (subtotal < 20) {
        const needed = 20 - subtotal;
        return `Add $${needed.toFixed(2)} more to save $3!`;
    }
    else if (subtotal < 40) {
        const needed = 40 - subtotal;
        return `Add $${needed.toFixed(2)} more to save $6!`;
    }
    // If 40..75 => discount=6, next threshold is 75 but that actually removes the discount (0).
    // Possibly you don't want to encourage them to remove the discount, so we won't message them.
    // if (subtotal < 75) ...
    return "";
}
exports.getNextThresholdMessage = getNextThresholdMessage;
