import React, { useEffect, useState } from "react";
import { MenuItem, Modifier } from "@redz/shared";
import { parseBaseAndProteinWithCategoryCheck } from "./parseBaseAndProtein";
import { cleanupName } from "./cleanupName";

function CollapsibleSection({
  title,
  children,
  defaultOpen = false,
}: {
  title: string;
  children: React.ReactNode;
  defaultOpen?: boolean;
}) {
  const [isOpen, setIsOpen] = useState(defaultOpen);

  return (
    <div className="mb-3">
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="w-full flex items-center justify-between bg-gray-200 px-3 py-2 rounded"
      >
        <span className="font-semibold">{title}</span>
        <span className="symbol-font">{isOpen ? "−" : "+"}</span>
      </button>
      {isOpen && <div className="mt-2 ml-3">{children}</div>}
    </div>
  );
}

function subdivideModifiersByKeywords(modifiers: Modifier[]) {
  const subGroups = {
    noLight: [] as Modifier[],
    addExtra: [] as Modifier[],
    side: [] as Modifier[],
    other: [] as Modifier[],
  };

  for (const mod of modifiers) {
    const lower = mod.name.toLowerCase();
    if (lower.startsWith("no ") || lower.startsWith("light")) {
      subGroups.noLight.push(mod);
    } else if (lower.startsWith("add ") || lower.startsWith("extra")) {
      subGroups.addExtra.push(mod);
    } else if (lower.includes("side")) {
      subGroups.side.push(mod);
    } else {
      subGroups.other.push(mod);
    }
  }
  return subGroups;
}

interface VariantAndModifierModalProps {
  baseTitle: string;
  variantItems: MenuItem[];
  onConfirm: (variantId: number | null, selectedMods: Modifier[]) => void;
  onClose: () => void;
}

const VariantAndModifierModal: React.FC<VariantAndModifierModalProps> = ({
  baseTitle,
  variantItems,
  onConfirm,
  onClose,
}) => {
  const [selectedIndex, setSelectedIndex] = useState<number>(-1);
  const [chosenModifiers, setChosenModifiers] = useState<Record<number, boolean>>({});

  // Track the minimum base price among variants
  const [minPrice, setMinPrice] = useState<number>(Infinity);

  useEffect(() => {
    if (variantItems.length === 1) {
      setSelectedIndex(0);
    }
    if (variantItems.length === 0) {
      setMinPrice(Infinity);
    } else {
      const prices = variantItems.map((it) => it.base_price ?? Infinity);
      setMinPrice(Math.min(...prices));
    }
  }, [variantItems]);

  function getSelectedItem(): MenuItem | null {
    if (selectedIndex < 0 || selectedIndex >= variantItems.length) return null;
    return variantItems[selectedIndex];
  }

  function getActiveModifiers(): Modifier[] {
    const item = getSelectedItem();
    if (!item || !item.modifier_groups) return [];
    return item.modifier_groups.flatMap((g) => g.modifiers);
  }

  const activeModifiers = getActiveModifiers();
  const { noLight, addExtra, side, other } = subdivideModifiersByKeywords(activeModifiers);

  function toggleModifier(modId: number) {
    setChosenModifiers((prev) => ({ ...prev, [modId]: !prev[modId] }));
  }

  function handleConfirm() {
    const item = getSelectedItem();
    const variantId = item ? item.id : null;
    const selectedMods: Modifier[] = [];
    for (const mod of activeModifiers) {
      if (chosenModifiers[mod.id]) {
        selectedMods.push(mod);
      }
    }
    onConfirm(variantId, selectedMods);
  }

  function getProteinDisplay(item: MenuItem): string {
    const { baseName, proteinName } = parseBaseAndProteinWithCategoryCheck(
      item.name,
      item.categoryName ?? ""
    );
    if (proteinName) return proteinName;
    return cleanupName(baseName || item.name, item.categoryName ?? "");
  }

  // If user clicks the dark backdrop, close the modal
  function handleBackdropClick(e: React.MouseEvent<HTMLDivElement>) {
    // Only close if the user actually clicked the backdrop itself, not a child
    if (e.target === e.currentTarget) {
      onClose();
    }
  }

  // RENDER VARIANT SELECTOR
  function renderVariantSelector() {
    if (variantItems.length <= 1) return null;

    return (
      <div className="mb-6">
        <h3 className="text-md font-semibold mb-2 text-gray-800">Select Your Protein</h3>
        {minPrice < Infinity && (
          <p className="mb-2 text-sm text-gray-700">
            Base Price: <strong>${minPrice.toFixed(2)}</strong>
          </p>
        )}
        <ul className="space-y-2">
          {variantItems.map((itm, idx) => {
            const isSelected = idx === selectedIndex;
            const proteinText = getProteinDisplay(itm);
            const itemPrice = itm.base_price ?? 0;
            const priceDiff = itemPrice - minPrice;

            return (
              <li
                key={itm.id}
                onClick={() => {
                  setSelectedIndex(idx);
                  setChosenModifiers({});
                }}
                className={`p-3 border rounded transition cursor-pointer hover:bg-gray-100 
                  ${isSelected ? "bg-gray-200 border-redz-red" : ""}`}
              >
                <div className="flex items-center justify-between">
                  <div>
                    <div className="text-md font-semibold text-redz-red">
                      {proteinText}
                    </div>
                    {itm.description && (
                      <div className="text-sm text-gray-600 line-clamp-2">
                        {itm.description}
                      </div>
                    )}
                  </div>
                  {/* Show offset if > 0 */}
                  <div className="text-base font-bold ml-4">
                    {priceDiff > 0 && (
                      <span className="ml-1 text-sm text-gray-600">
                        (+${priceDiff.toFixed(2)})
                      </span>
                    )}
                  </div>
                </div>
              </li>
            );
          })}
        </ul>
      </div>
    );
  }

  // RENDER MODIFIERS
  function renderModifierSections() {
    if (variantItems.length > 1 && selectedIndex < 0) {
      return <p className="text-gray-500 italic">Please select your protein option above.</p>;
    }
    if (activeModifiers.length === 0) {
      return <p className="text-gray-500">No modifiers available.</p>;
    }
    return (
      <div className="mb-6">
        <h3 className="text-md font-semibold mb-2 text-gray-800">Customize Your Order</h3>
        {/* No/Light Section */}
        {noLight.length > 0 && (
          <CollapsibleSection title="No / Light">
            {noLight.map((mod) => {
              const isSelected = !!chosenModifiers[mod.id];
              return (
                <div key={mod.id} className="flex items-center space-x-2 mb-2">
                  <input
                    type="checkbox"
                    checked={isSelected}
                    onChange={() => toggleModifier(mod.id)}
                  />
                  <label>
                    {mod.name}
                    {mod.price > 0 && (
                      <span className="text-sm text-gray-600 ml-1">
                        (+${mod.price.toFixed(2)})
                      </span>
                    )}
                  </label>
                </div>
              );
            })}
          </CollapsibleSection>
        )}

        {/* Add/Extra */}
        {addExtra.length > 0 && (
          <CollapsibleSection title="Add / Extra">
            {addExtra.map((mod) => {
              const isSelected = !!chosenModifiers[mod.id];
              return (
                <div key={mod.id} className="flex items-center space-x-2 mb-2">
                  <input
                    type="checkbox"
                    checked={isSelected}
                    onChange={() => toggleModifier(mod.id)}
                  />
                  <label>
                    {mod.name}
                    {mod.price > 0 && (
                      <span className="text-sm text-gray-600 ml-1">
                        (+${mod.price.toFixed(2)})
                      </span>
                    )}
                  </label>
                </div>
              );
            })}
          </CollapsibleSection>
        )}

        {/* On the Side */}
        {side.length > 0 && (
          <CollapsibleSection title="On the Side">
            {side.map((mod) => {
              const isSelected = !!chosenModifiers[mod.id];
              return (
                <div key={mod.id} className="flex items-center space-x-2 mb-2">
                  <input
                    type="checkbox"
                    checked={isSelected}
                    onChange={() => toggleModifier(mod.id)}
                  />
                  <label>
                    {mod.name}
                    {mod.price > 0 && (
                      <span className="text-sm text-gray-600 ml-1">
                        (+${mod.price.toFixed(2)})
                      </span>
                    )}
                  </label>
                </div>
              );
            })}
          </CollapsibleSection>
        )}

        {/* Other */}
        {other.length > 0 && (
          <CollapsibleSection title="Other Options">
            {other.map((mod) => {
              const isSelected = !!chosenModifiers[mod.id];
              return (
                <div key={mod.id} className="flex items-center space-x-2 mb-2">
                  <input
                    type="checkbox"
                    checked={isSelected}
                    onChange={() => toggleModifier(mod.id)}
                  />
                  <label>
                    {mod.name}
                    {mod.price > 0 && (
                      <span className="text-sm text-gray-600 ml-1">
                        (+${mod.price.toFixed(2)})
                      </span>
                    )}
                  </label>
                </div>
              );
            })}
          </CollapsibleSection>
        )}
      </div>
    );
  }

  const hasSelectedVariant = variantItems.length === 1 || selectedIndex >= 0;

  return (
    // 1) The backdrop
    <div
      className="fixed inset-0 bg-black bg-opacity-40 z-50 flex items-center justify-center"
      onClick={handleBackdropClick}
    >
      {/* 2) The modal container (click inside => stopPropagation) */}
      <div
        onClick={(e) => e.stopPropagation()}
        className="bg-white w-[90%] max-w-2xl h-[90vh] flex flex-col rounded-md shadow-lg relative"
      >
        {/* HEADER */}
        <div className="flex items-center justify-between p-4 border-b shrink-0">
          <h2 className="text-xl font-bold text-redz-red">{baseTitle}</h2>
          <button
            onClick={onClose}
            className="text-gray-600 hover:text-red-600 text-2xl font-bold"
          >
            &times;
          </button>
        </div>

        {/* SCROLLABLE CONTENT */}
        <div className="overflow-y-auto flex-1 p-4">
          {renderVariantSelector()}
          {renderModifierSections()}
        </div>

        {/* FOOTER */}
        <div className="p-4 border-t shrink-0 flex justify-end space-x-3">
          <button
            className="px-4 py-2 bg-gray-300 rounded"
            onClick={onClose}
          >
            Cancel
          </button>

          {/* If user hasn't selected a variant, show a disabled button with a tooltip */}
          {!hasSelectedVariant ? (
            <div className="relative group">
              <button
                className="px-4 py-2 bg-gray-400 text-white rounded cursor-not-allowed"
                disabled
              >
                Add to Cart
              </button>
              {/* Tooltip */}
              <span className="
                absolute w-max px-2 py-1 text-sm text-white bg-black rounded bottom-full left-1/2 
                transform -translate-x-1/2 -mb-2 
                opacity-0 pointer-events-none group-hover:opacity-100
                transition-opacity duration-200 text-balance
              ">
                Please select <br/>your protein first.
              </span>
            </div>
          ) : (
            <button
              className="px-4 py-2 bg-red-600 text-white rounded"
              onClick={handleConfirm}
            >
              Add to Cart
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default VariantAndModifierModal;