import React from 'react';
import { observer } from 'mobx-react-lite';
import { cartStore } from '@redz/shared';
// Import the cart+badge SVG as a React component:
import { ReactComponent as CartBubbleSvg } from '../../../assets/images/CartBubble.svg';

interface CartIconProps {
  onClick?: () => void;
}

const CartIcon: React.FC<CartIconProps> = observer(({ onClick }) => {
  // Sum up the total quantity from cartStore
  const itemCount = cartStore.totalQuantity;

  return (
    <div
      className="relative cursor-pointer"
      onClick={onClick}
      aria-label="View Cart"
      title="View Cart"
    >
      {/* The base SVG (the cart + bubble shape). 
          If your CartBubble.svg is *only* the cart shape, 
          you might need to manually position a badge. 
      */}
      <CartBubbleSvg className="w-10 h-10" />

      {/* If you need your own separate red bubble, 
          you could absolutely-position it, e.g.:
          <div className="absolute top-0 right-0 w-5 h-5 bg-red-600 rounded-full flex items-center justify-center text-white text-sm">
            {itemCount}
          </div>
      */}
      {/* But if CartBubble.svg already has the bubble, 
          you can place the itemCount in a text element positioned over it: */}
      <span
        className="absolute top-0 right-0 transform translate-x-1/2 -translate-y-1/2
                   bg-red-600 text-white text-xs w-5 h-5 rounded-full flex items-center justify-center"
      >
        {itemCount}
      </span>
    </div>
  );
});

export default CartIcon;