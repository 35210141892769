import React from 'react';

interface MenuHeroLabelProps {
  title: string;
  description: string;
}

export default function MenuHeroLabel({
  title,
  description
}: MenuHeroLabelProps) {
  // Optionally split the description into two lines
  const splitText = (text: string): [string, string] => {
    const midpoint = Math.floor(text.length / 2);
    const lastSpace = text.lastIndexOf(' ', midpoint);
    if (lastSpace === -1) return [text, ''];
    return [text.substring(0, lastSpace), text.substring(lastSpace + 1)];
  };

  const [firstLine, secondLine] = splitText(description);

  return (
    <svg
      // Adjusted viewBox to tightly fit content
      viewBox="0 0 620 170" // Example values; replace with actual bounds
      xmlns="http://www.w3.org/2000/svg"
      className="w-full h-auto"
      aria-labelledby="menuHeroLabelTitle menuHeroLabelDesc"
      role="img"
    >
      <title id="menuHeroLabelTitle">{title}</title>
      <desc id="menuHeroLabelDesc">{description}</desc>

      {/* Decorative Path */}
      <path
        className="fill-[#f0e2cb]"
        d="M4.69,1.4s168.85,3.96,343.64,0c174.79-3.96,268.19,1.76,268.19,1.76,0,0,3.31,1.95.94,3.44-2.37,1.49-9.02.87-6.29,2.6,2.73,1.73,5.22.37,4.63,2.23-.59,1.86-4.03,1.24-3.92,2.35.12,1.11,5.58.25,3.56,2.35-2.02,2.1-5.46,1.49-4.39,2.85,1.07,1.36,4.51,1.61,2.97,2.6-1.54.99,3.92.74,1.42,2.85-2.49,2.1-5.7,1.86-2.97,2.72,2.73.87,1.9,3.47,1.9,3.47,0,0-4.63.87-1.42,3.34,3.2,2.48,7.36-1.24,2.49,2.85-4.86,4.09-4.51,4.83-4.51,4.83,0,0,12.75,5.57,7.38,6.31-5.37.74,8.04,2.97-.5,3.84-8.54.87-1.54,3.96-5.58,11.27-4.03,7.3-10.09,1.61-2.61,8.79,7.48,7.18,17.32,4.09,9.02,10.52-8.31,6.44-9.73,8.91-9.73,8.91,0,0,9.97,3.34,5.93,7.06-4.03,3.71-7.36,4.21-5.1,5.82,2.25,1.61-1.31,3.22-1.31,3.22,0,0,18.39,12.88,9.85,20.8-8.54,7.92-15.43,2.97-12.1,7.92,3.32,4.95,12.34,11.39,3.56,13.87-8.78,2.48,5.7-1.98,5.46,5.94,0,0-76.25,3.96-122.37,5.45-46.13,1.49-329.49-5.2-329.49-5.2l-158.65,2.23s-2.73-2.48.59-2.97c3.32-.5,5.22-1.94,1.9-2.33-3.32-.39-8.28.6-3.55-1.63,4.73-2.23,9.48,0,5.33-2.6-4.15-2.6-7.59-.99-6.29-3.47,1.31-2.48,6.29-1.49,5.1-3.22-1.19-1.73-3.32-7.8-1.66-9.41,1.66-1.61,7.36,1.86,2.14-2.35-5.22-4.21-9.73-2.48-4.63-5.32,5.1-2.85,10.56-1.11,5.58-3.71-4.98-2.6-4.98-5.94-2.14-9.04,2.85-3.1,7.36-8.3,3.8-9.53-3.56-1.24,2.61-1.36,3.44-4.33.83-2.97,3.92-2.11-1.66-4.09-5.58-1.98-5.1-2.6-1.9-3.34,3.2-.74,5.34-5.32,3.44-5.7-1.9-.37-11.15-3.22-5.58-5.94,5.58-2.72,4.75-4.46,4.75-4.46,0,0-10.21-.62-8.9-8.67,1.31-8.05,8.43-3.96,4.75-8.54-3.68-4.58-9.26-2.23-6.88-5.82,2.37-3.59,10.56-4.09,8.9-6.07-1.66-1.98-11.04-.62-10.8-4.09.24-3.47,8.54-.99,5.7-4.46-2.85-3.47,2.37-5.7.12-8.17-2.25-2.48,5.34-.99.36-4.21-4.98-3.22-2.85-4.21-1.54-6.07,1.31-1.86,3.2-2.48.59-3.96-2.61-1.49-.95-1.24-.95-1.24,0,0,7.36-.87,2.73-2.48-4.63-1.61-6.65,2.97-5.22-1.86C3.62,4.49-5.04,3.75,4.69,1.4Z"
      />

      {/* Centered Title */}
      <text
        x="50%"
        y="26%" 
        textAnchor="middle"
        dominantBaseline="middle"
        className="font-nexa text-black text-[55pt]"
      >
        {title}
      </text>

      {/* Centered Description on Two Lines */}
      <text
        x="50%"
        y="65%"
        textAnchor="middle"
        dominantBaseline="middle"
        className="font-myriad text-center text-balance text-2xl"
      >
        {/* First Line */}
        <tspan x="50%" dy="-0.6em">
          {firstLine}
        </tspan>
        {/* Second Line */}
        <tspan x="50%" dy="1.2em">
          {secondLine}
        </tspan>
      </text>
    </svg>
  );
}