// src/stores/CateringStore.ts
import { makeAutoObservable, runInAction } from "mobx";
import { API } from "@redz/shared";

export interface PriceTier {
  minGuests: number;
  maxGuests?: number;
  pricePerPerson: number;
}
export interface UpgradeLevel {
  label: string;
  pricePerPerson: number;
  appetizerLimit: number;
  entreeLimit: number;
  dessertOptions: string[];
}
export interface SubPackage {
  name: string;
  items: string[];
  notes?: string;
}

export interface CateringPackage {
  slug_id: string;
  name: string;
  description: string;
  priceTiers?: PriceTier[];
  subPackages?: SubPackage[];
  upgradeLevels?: UpgradeLevel[];
}

export interface CateringAddOn {
  slug_id: string;
  label: string;
  pricePerPerson: number;
  description?: string;
}

class CateringStore {
  // Data from the server
  packages: CateringPackage[] = [];
  addons: CateringAddOn[] = [];

  // Contact info
  name: string = "";
  email: string = "";
  phone: string = "";
  userMessage: string = "";


  // Event details
  eventDate: Date | null = null;           // e.g. "2024-05-10"
  eventType: string = "";           // e.g. "Wedding", "Corporate", etc.
  serviceHours: number | null = null;  // allow null if user hasn't specified

  // Selected Package info
  selectedPackageSlug: string | null = null;
  selectedUpgradeIndex: number | null = null;
  guestCount: number = 40;
  selectedAddOns: Record<string, boolean> = {};

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
    this.fetchCateringData();
  }

  // ---------------------------
  // Fetch + Transform
  // ---------------------------
  async fetchCateringData() {
    try {
      const res = await API.get("/api/catering/data");
      runInAction(() => {
        // transform snake_case => camelCase
        const transformedPackages = res.data.packages.map((pkg: any) => ({
          ...pkg,
          priceTiers: pkg.price_tiers?.map((pt: any) => ({
            minGuests: pt.min_guests,
            maxGuests: pt.max_guests,
            pricePerPerson: parseFloat(pt.price_per_person),
          })) || [],
          subPackages: pkg.sub_packages?.map((sp: any) => ({
            name: sp.name,
            notes: sp.notes,
            items: sp.items?.map((it: any) => it.name) || [],
          })) || [],
          upgradeLevels: pkg.upgrade_levels?.map((ul: any) => ({
            label: ul.label,
            pricePerPerson: parseFloat(ul.price_per_person),
            appetizerLimit: ul.appetizer_limit,
            entreeLimit: ul.entree_limit,
            dessertOptions: ul.dessert_options,
          })) || [],
        }));

        const transformedAddons = res.data.addons.map((addon: any) => ({
          slug_id: addon.slug_id,
          label: addon.label,
          pricePerPerson: parseFloat(addon.price_per_person),
          description: addon.description,
        }));

        this.packages = transformedPackages;
        this.addons = transformedAddons;
      });
    } catch (err) {
      console.error("Failed to load catering data:", err);
    }
  }

  // ---------------------------
  // Setters
  // ---------------------------
  setName(name: string) {
    this.name = name;
  }
  setEmail(email: string) {
    this.email = email;
  }
  setPhone(phone: string) {
    this.phone = phone;
  }
  setUserMessage(msg: string) {
    this.userMessage = msg;
  }
  setEventDate(date: Date | null) {
    this.eventDate = date;
  }
  setEventType(type: string) {
    this.eventType = type;
  }
  setServiceHours(hours: number | null) {
    this.serviceHours = hours;
  }

  setSelectedPackageSlug(slug: string) {
    this.selectedPackageSlug = slug;
    const pkg = this.packages.find((p) => p.slug_id === slug);
    // If no upgrade levels => reset index
    if (!pkg?.upgradeLevels || pkg.upgradeLevels.length === 0) {
      this.selectedUpgradeIndex = null;
    }
  }

  setSelectedUpgradeIndex(idx: number | null) {
    this.selectedUpgradeIndex = idx;
  }

  setGuestCount(count: number) {
    this.guestCount = count;
  }

  toggleAddOn(slugId: string) {
    this.selectedAddOns[slugId] = !this.selectedAddOns[slugId];
  }

  // ---------------------------
  // Computed
  // ---------------------------
  get selectedPackage(): CateringPackage | null {
    if (!this.selectedPackageSlug) return null;
    return this.packages.find((p) => p.slug_id === this.selectedPackageSlug) || null;
  }

  get billableGuests(): number {
    return Math.max(this.guestCount, 40);
  }

  get basePricePerPerson(): number {
    const pkg = this.selectedPackage;
    if (!pkg) return 0;

    if (pkg.upgradeLevels && pkg.upgradeLevels.length > 0) {
      let tierIndex = this.selectedUpgradeIndex ?? 0;
      if (tierIndex < 0 || tierIndex >= pkg.upgradeLevels.length) tierIndex = 0;
      return pkg.upgradeLevels[tierIndex].pricePerPerson;
    }

    if (pkg.priceTiers && pkg.priceTiers.length > 0) {
      let found = pkg.priceTiers.find((t) => {
        if (t.maxGuests) {
          return this.billableGuests >= t.minGuests && this.billableGuests <= t.maxGuests;
        }
        return this.billableGuests >= t.minGuests;
      });
      if (!found) found = pkg.priceTiers[0];
      return found?.pricePerPerson || 0;
    }

    return 0;
  }

  get addOnsTotalPerPerson(): number {
    let sum = 0;
    for (const addOn of this.addons) {
      if (this.selectedAddOns[addOn.slug_id]) {
        sum += addOn.pricePerPerson;
      }
    }
    return sum;
  }

  get subtotal(): number {
    const perPerson = this.basePricePerPerson + this.addOnsTotalPerPerson;
    return perPerson * this.billableGuests;
  }

  get serviceFee(): number {
    return this.subtotal * 0.2;
  }

  get total(): number {
    return this.subtotal + this.serviceFee;
  }

  // ---------------------------
  // Book Catering
  // ---------------------------
  async bookCatering() {
    if (!this.selectedPackage) {
      throw new Error("No package selected");
    }
    // If serviceHours is null => default to 0
    const finalHours = this.serviceHours ?? 0;
    const finalDate = this.eventDate
      ? this.eventDate.toISOString().slice(0,10) // "YYYY-MM-DD"
      : null;

    const payload = {
      // package
      package_slug: this.selectedPackage.slug_id,
      guest_count: this.billableGuests,
      upgrade_index: this.selectedUpgradeIndex,
      addons: Object.keys(this.selectedAddOns).filter((k) => this.selectedAddOns[k]),

      // contact
      name: this.name,
      email: this.email,
      phone: this.phone,
      user_message: this.userMessage,

      // event details
      event_date: finalDate,
      event_type: this.eventType,
      service_hours: finalHours,
    };

    try {
      const res = await API.post("/api/catering/create-booking", payload);
      return res.data; // e.g. { cateringOrderId, clientSecret }
    } catch (err) {
      console.error("Booking failed:", err);
      throw err;
    }
  }
}

const cateringStore = new CateringStore();
export default cateringStore;