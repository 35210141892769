import React, { Suspense } from 'react';
import Root from './pieces/Root';
import { Route, Routes } from 'react-router-dom';
import ErrorBoundary from './pieces/Root/ErrorBoundary';
import Home from './pieces/Home';
import ContactUs from './pieces/ContactUs';
import Menu from './pieces/Menu';
import AboutUs from './pieces/AboutUs';
import Catering from './pieces/Catering';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import Order from './pieces/Order';
import Congratulations from './pieces/Order/Congratulations';

const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_PK!}`); // or from .env

const App = () => {
  return (
    <Root>
      <ErrorBoundary>
      {/* Wrap the routes with Suspense and provide a fallback for loading state */}
        <Suspense fallback={<div>Loading...</div>}>
            <Routes>
              <Route path="/" element={<Home />}/>
              <Route path="/contact-us" element={<ContactUs />}/>
              <Route path="/menu" element={<Menu />}/>
              <Route path="/about-us" element={<AboutUs />}/>
              <Route path="/congratulations" element={<Congratulations />} />
              <Route
                path="/order"
                element={
                  <Elements stripe={stripePromise}>
                    <Order />
                  </Elements>
                }
              />
              <Route
                path="/catering"
                element={
                  <Elements stripe={stripePromise}>
                    <Catering />
                  </Elements>
                }
              />
              {/* <Route path="/contact-us" element={<ContactUs />}/>
              <Route path="/about-us" element={<AboutUs />}/>
              <Route path="/menus" element={<Menu />}>
                <Route path="sb" element={<SantaBarbaraMenu />} />
                <Route path="np" element={<NewburyParkMenu />} />
              </Route>
              <Route path="/locations" element={<Locations />}>
                <Route path="sb" element={<SantaBarbaraLocation />} />
                <Route path="np" element={<NewburyParkLocation />} />
              </Route> */}
            </Routes>
        </Suspense>
        </ErrorBoundary>
    </Root>
  );
};

export default App;