/**
 * cleanupName.ts
 * 
 * Removes leading "Side Of" if in "Sides" category,
 * unifies synonyms ("quesotaco" => "Queso Taco"), 
 * removes repeated phrases ("Ramen Ramen" => "Ramen"), etc.
 */

const synonymsMap: Record<string, string> = {
    // unify variations to a single name
    // you'll want to keep these keys lowercase (no spaces)
    "quesotaco": "Queso Taco",
    "queso taco": "Queso Taco",
    "queso-taco": "Queso Taco",
    "quesotacos": "Queso Tacos",
    "queso tacos": "Queso Tacos",
    // etc. (expand for other known variations)
  };
  
  export function cleanupName(rawName: string, categoryName: string | undefined): string {
    let name = rawName.trim();
  
    // 1) If category is "Sides", remove "Side Of "
    if (categoryName && categoryName.toLowerCase() === "sides") {
      name = name.replace(/^side of\s+/i, "");
      name = name.replace(/^side\s+/i, "");
    }
  
    // 2) Remove exact repeated phrases: "Ramen Ramen" => "Ramen"
    name = removeExactRepeat(name);
  
    // 3) Lowercase + remove some punctuation/spaces to find synonyms
    const normalizedKey = name.toLowerCase().replace(/\s+/g, "").replace(/-/g, "");
  
    if (synonymsMap[normalizedKey]) {
      name = synonymsMap[normalizedKey];
    }
    
    // You could also handle partial synonyms or advanced logic here if needed.
  
    return name.trim();
  }
  
  /**
   * Splits a string into tokens. If the string is repeated
   * exactly twice, return just the first half.
   *
   * e.g. "Ramen Ramen" => "Ramen"
   *      "Quesotaco Quesotaco" => "Quesotaco"
   */
  function removeExactRepeat(str: string): string {
    const tokens = str.split(/\s+/);
    const len = tokens.length;
  
    if (len % 2 === 0) {
      const half = len / 2;
      const firstHalf = tokens.slice(0, half).join(" ");
      const secondHalf = tokens.slice(half).join(" ");
      if (firstHalf.toLowerCase() === secondHalf.toLowerCase()) {
        return firstHalf;
      }
    }
    return str;
  }