import React, { useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { motion } from 'framer-motion';
import cartStore from '@redz/shared/dist/stores/CartStore';
import CheckoutModal from './CheckoutModal';

// 1) Import your variant modal
import VariantAndModifierModal from './VariantAndModifierModal';

interface CartSidebarProps {
  isOpen: boolean;
  onClose: () => void;
  highlightLineItemId?: string | null;
}

const CartSidebar: React.FC<CartSidebarProps> = observer(
  ({ isOpen, onClose, highlightLineItemId }) => {
    const {
      cartItems,
      removeLineItem,
      lineItemTotal,
      totalQuantity,
      cartSubtotal,
      cartTax,
      cartSubtotalAfterPromo,
      updateQuantity,
      deliveryType,
      setDeliveryType,
      restaurantDiscount,
      nextTierDiscountInfo,
      // We can use addToCart to re-add an edited item:
      addToCart,
    } = cartStore;

    const [showCheckoutModal, setShowCheckoutModal] = useState(false);

    // 2) State for editing
    const [showEditModal, setShowEditModal] = useState(false);
    const [editLineItem, setEditLineItem] = useState<any | null>(null);

    // Swipe references
    const startX = useRef<number | null>(null);

    // Touch logic: if user swipes left
    function handleTouchStart(e: React.TouchEvent<HTMLDivElement>) {
      startX.current = e.touches[0].clientX;
    }
    function handleTouchMove(e: React.TouchEvent<HTMLDivElement>) {
      if (startX.current === null) return;
      const currentX = e.touches[0].clientX;
      const diff = currentX - startX.current;
      // If diff < 80 => close 
      if (diff < -80) { // you might want -80 for left swipe
        onClose();
      }
    }

    // Scroll into view for highlight
    useEffect(() => {
      if (highlightLineItemId) {
        const el = document.getElementById(`cart-item-${highlightLineItemId}`);
        if (el) {
          el.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
      }
    }, [highlightLineItemId]);

    // 3) Function to open the edit modal
    function openEditModal(lineItem: any) {
      setEditLineItem(lineItem);
      setShowEditModal(true);
    }

    // 4) Called when user confirms changes in the modal
    function handleEditConfirm(
      newVariantId: number | null,
      updatedModifiers: any[]
    ) {
      if (!editLineItem) return;

      // Remove old item
      removeLineItem(editLineItem.id);

      // If you want them to be able to switch proteins:
      // find the new menu item from your entire store:
      // e.g. let newMenuItem = storeItems.find(i => i.id === newVariantId);
      // If you only want to keep the same item:
      let newMenuItem = editLineItem.menuItem;
      
      // If newVariantId is not null, you'd do:
      // if (newVariantId) {
      //   newMenuItem = storeItems.find((itm) => itm.id === newVariantId) || editLineItem.menuItem;
      // }

      // Re-add to cart with the updated modifiers
      addToCart(newMenuItem, editLineItem.quantity, updatedModifiers);

      // Close
      setShowEditModal(false);
      setEditLineItem(null);
    }

    // Return early if not open
    if (!isOpen) return null;

    return (
      <>
        {/* Dark overlay */}
        <motion.div
          className="fixed inset-0 bg-black bg-opacity-50 z-40"
          initial={{ opacity: 0 }}
          animate={{ opacity: 0.5 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.3 }}
          onClick={onClose}
        />

        {/* Sidebar */}
        <motion.div
          key="cartSidebar"
          className="fixed top-0 right-0 w-[320px] max-w-full h-full bg-white shadow-2xl z-50 flex flex-col"
          initial={{ x: '100%' }}
          animate={{ x: 0 }}
          exit={{ x: '100%' }}
          transition={{ duration: 0.3, ease: 'easeInOut' }}
          onTouchStart={handleTouchStart}
          onTouchMove={handleTouchMove}
        >
          {/* Header */}
          <div className="p-4 flex items-center justify-between border-b">
            <h2 className="text-xl font-bold">Your Cart</h2>
            <button onClick={onClose} className="text-red-500 font-bold">X</button>
          </div>

          {/* Cart Items */}
          <div className="flex-1 overflow-y-auto p-4 space-y-4">
            {cartItems.length === 0 ? (
              <p className="text-gray-500">Your cart is empty.</p>
            ) : (
              cartItems.map(lineItem => {
                const isHighlighted = highlightLineItemId === lineItem.id;
                return (
                  <motion.div
                    key={lineItem.id}
                    id={`cart-item-${lineItem.id}`}
                    className={`p-2 border-b border-gray-200 ${
                      isHighlighted ? 'bg-yellow-100' : ''
                    }`}
                    initial={{ opacity: 0, scale: 0.9 }}
                    animate={{ opacity: 1, scale: 1 }}
                    transition={{ type: 'spring', stiffness: 200 }}
                  >
                    <div className="flex justify-between items-start">
                      <div>
                        <p className="font-semibold text-red-600">
                          {lineItem.menuItem.name}
                        </p>
                        {lineItem.selectedModifiers && lineItem.selectedModifiers?.length > 0 && (
                          <p className="text-sm text-gray-600">
                            Modifiers:{" "}
                            {lineItem.selectedModifiers
                              .map(m => m.name)
                              .join(", ")}
                          </p>
                        )}
                        {/* Quantity controls */}
                        <div className="flex items-center space-x-2 mt-1">
                          <button
                            onClick={() =>
                              updateQuantity(lineItem.id, lineItem.quantity - 1)
                            }
                            className="px-2 py-1 bg-gray-200 rounded text-sm hover:bg-gray-300"
                          >
                            -
                          </button>
                          <span className="font-semibold">
                            {lineItem.quantity}
                          </span>
                          <button
                            onClick={() =>
                              updateQuantity(lineItem.id, lineItem.quantity + 1)
                            }
                            className="px-2 py-1 bg-gray-200 rounded text-sm hover:bg-gray-300"
                          >
                            +
                          </button>
                        </div>
                      </div>
                      <div className="text-right">
                        <p className="font-semibold">
                          ${lineItemTotal(lineItem).toFixed(2)}
                        </p>
                        <button
                          onClick={() => removeLineItem(lineItem.id)}
                          className="text-xs text-red-600 underline mt-1"
                        >
                          Remove
                        </button>

                        {/* Edit button */}
                        <button
                          onClick={() => openEditModal(lineItem)}
                          className="text-xs text-blue-600 underline ml-2"
                        >
                          Edit
                        </button>
                      </div>
                    </div>
                  </motion.div>
                );
              })
            )}
          </div>

          {/* Footer */}
          {cartItems.length > 0 && (
            <div className="p-4 border-t">
              <div className="flex items-center space-x-4 mb-4">
                <label
                  className={`px-3 py-2 border rounded cursor-pointer ${
                    deliveryType === "pickup"
                      ? "bg-red-500 text-white"
                      : "bg-white text-black"
                  }`}
                  onClick={() => setDeliveryType("pickup")}
                >
                  Pickup
                </label>
                <label
                  className={`px-3 py-2 border rounded cursor-pointer ${
                    deliveryType === "delivery"
                      ? "bg-red-500 text-white"
                      : "bg-white text-black"
                  }`}
                  onClick={() => setDeliveryType("delivery")}
                >
                  Delivery
                </label>
              </div>

              <div className="flex justify-between items-center mb-1">
                <span className="font-semibold">Items:</span>
                <span>{totalQuantity}</span>
              </div>
              <div className="flex justify-between items-center mb-1">
                <span className="font-semibold">Subtotal:</span>
                <span>${cartSubtotal.toFixed(2)}</span>
              </div>
              <div className="flex justify-between items-center mb-1">
                <span className="font-semibold">Tax (8.75%):</span>
                <span>${cartTax.toFixed(2)}</span>
              </div>
              <div className="flex justify-between items-center mb-4">
                <span className="font-semibold">Total:</span>
                <span className="text-lg font-bold">
                  ${(cartSubtotalAfterPromo + cartTax).toFixed(2)}
                </span>
              </div>

              {/* If in delivery mode, show discount teasers */}
              {deliveryType === 'delivery' && (
                <>
                  <div className="mb-2 p-2 bg-blue-50 rounded">
                    {restaurantDiscount === 0 && (
                      <p className="text-blue-700 text-sm">
                        Spend $20+ to unlock a discount on your delivery!
                      </p>
                    )}
                    {restaurantDiscount > 0 && Number.isFinite(restaurantDiscount) && (
                      <p className="text-blue-700 text-sm font-medium">
                        You are saving ${restaurantDiscount.toFixed(2)}!
                      </p>
                    )}
                    {restaurantDiscount === Number.POSITIVE_INFINITY && (
                      <p className="text-blue-700 text-sm font-medium">
                        Free delivery on orders of $75 or more!
                      </p>
                    )}
                  </div>

                   {/* Next Tier Prompt */}
                   {nextTierDiscountInfo && nextTierDiscountInfo.extraSavings > 0 && (
                    <div className="mb-2 p-2 bg-green-50 rounded">
                      {nextTierDiscountInfo.currentDiscount === 0 ? (
                        <p className="text-green-700 text-sm">
                          Add <strong>${nextTierDiscountInfo.needed.toFixed(2)}</strong> more to
                          get a discount of <strong>${nextTierDiscountInfo.nextDiscount.toFixed(2)}</strong>!
                        </p>
                      ) : Number.isFinite(nextTierDiscountInfo.nextDiscount)? (
                        <p className="text-green-700 text-sm">
                          Add <strong>${nextTierDiscountInfo.needed.toFixed(2)}</strong> more to
                          save another ${nextTierDiscountInfo.extraSavings.toFixed(2)}! 
                        </p>
                      ) : (
                        <p className="text-green-700 text-sm">
                          Add <strong>${nextTierDiscountInfo.needed.toFixed(2)}</strong> more to get 
                          FREE delivery (Save 
                          {nextTierDiscountInfo.currentDiscount > 0 
                            ? ` ~$3 more`
                            : " the entire cost"
                          })!
                        </p>
                      )}
                    </div>
                  )}
                </>
              )}

              <button
                onClick={() => setShowCheckoutModal(true)}
                className="bg-red-500 text-white py-2 px-4 rounded w-full"
              >
                Checkout
              </button>
            </div>
          )}

          <CheckoutModal
            isOpen={showCheckoutModal}
            onClose={() => setShowCheckoutModal(false)}
            restaurantDiscount={
              deliveryType === 'delivery' ? restaurantDiscount : 0
            }
          />
        </motion.div>

        {/* 5) The Edit Modal (VariantAndModifierModal) */}
        {showEditModal && editLineItem && (
          <VariantAndModifierModal
            baseTitle={`Edit: ${editLineItem.menuItem.name}`}
            // If you want them to pick a different protein from the same category:
            // variantItems={some array?} 
            // For just editing modifiers, pass [editLineItem.menuItem]
            variantItems={[editLineItem.menuItem]}
            // On confirm => remove old, re-add with new mods
            onConfirm={(variantId, updatedMods) => handleEditConfirm(variantId, updatedMods)}
            onClose={() => {
              setShowEditModal(false);
              setEditLineItem(null);
            }}
          />
        )}
      </>
    );
  }
);

export default CartSidebar;