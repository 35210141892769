// CateringDetails.tsx
import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';

import SectionTitle from '../../Menu/SectionTitle';
import PackageSelection from './PackageSelection';
import GuestCountInput from './GuestCountInput';
import AddOnsSelector from './AddOnsSelector';
import PricingSummary from './PricingSummary';

import StepContactAndEvent from './StepContactAndEvent';
import BookNowModal from './BookNowModal';
import contactFormStore from '../../../stores/ContactFormStore';
import cateringStore from '../../../stores/CateringStore';
import { toast } from 'react-toastify';

const CateringDetails: React.FC = observer(() => {
  const [showModal, setShowModal] = useState(false);

  async function handleInquireOnly() {
    contactFormStore.setField("name", cateringStore.name);
    contactFormStore.setField("email", cateringStore.email);
    contactFormStore.setField("phone", cateringStore.phone);
  
    // The user might not have typed a separate message. We'll auto-generate some details:
    let msg = `Inquiry about Catering:\nPackage: ${cateringStore.selectedPackageSlug}\nGuests: ${cateringStore.guestCount}\n\n`;
    msg += `Event Type: ${cateringStore.eventType}\nEvent Date: ${cateringStore.eventDate}\nHours: ${cateringStore.serviceHours}\n`;
    // If you store a userMessage in the store, you can append it here: + `Message: ${cateringStore.userMessage}\n`
  
    contactFormStore.setField("subject", "Catering");
    contactFormStore.setField("message", msg);
  
    await contactFormStore.submitForm();
  }
  
  function handleShowReview() {
    // Possibly check required fields: name/email, etc.
    if (!cateringStore.name || !cateringStore.email || !cateringStore.phone) {
      toast.error("Please provide name and email before continuing.");
      return;
    }
    setShowModal(true);
  }

  return (
    <div className="w-full bg-redz-white text-redz-red min-h-screen pt-[15vh] px-[5vw] pb-12">
      <SectionTitle title="Fiesta Options" black TacoColor="black" alternate />

      {/* 1) The user picks package, guests, add-ons */}
      <PackageSelection />
      <GuestCountInput />
      <AddOnsSelector />

      {/* 2) Pricing summary */}
      <PricingSummary />

      {/* 3) StepContactAndEvent if you want them to fill contact fields, or skip it if you already handle that */}
        <StepContactAndEvent
          onNext={handleShowReview}
          onInquire={handleInquireOnly}
        />

      {/* 4) Review & Pay => final step */}
      {showModal && <BookNowModal onClose={() => setShowModal(false)} />}
    </div>
  );
});

export default CateringDetails;


