import React from 'react';
import Slider, { Settings } from 'react-slick';
import ClippedImage from './ClippedImage';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

// Import the SVG label
import { ReactComponent as SignatureDishLabel } from '../../../assets/images/signature-dish-label.svg';
// Import the Dollar Sign SVG
import { ReactComponent as DollarSign } from '../../../assets/images/DollarSign.svg';

const dishes = [
    {
        imageUrl: "https://redz-bucket.s3.amazonaws.com/StreetTacos.webp",
        title: 'Street Taco Plate',
        description: '3 Soft Tacos, Beans or Rice, Consome with your choice of protein.\nProtein Choices: Birria, Asada, Chicken, Pastor, Veggies, Shrimp (+ $3.00)',
        price: '18',
    },
    {
        imageUrl: "https://redz-bucket.s3.amazonaws.com/BirriaPlate.webp",
        title: 'Yona Deluxe',
        description: '1 Mulita, 1 Quesotaco, 1 Street Taco, 1 Consome with your choice of protein. \nProtein Choices: Birria, Asada, Chicken, Pastor, Veggies, Shrimp (+ $3.00)',
        price: '18.49',
    },
    {
        imageUrl: "https://redz-bucket.s3.amazonaws.com/LunchSpecial.webp",
        title: 'Lunch Special',
        description: '2 Quesotacos, Rice, Beans, Consome with your choice of protein.\nProtein Choices: Birria, Asada, Chicken, Pastor, Veggies, Shrimp (+ $3.00)',
        price: '15',
    },
    {
        imageUrl: "https://redz-bucket.s3.amazonaws.com/Pizzadilla.webp",
        title: 'Pizzadilla',
        description: '2 Flour tortillas layered with Cheese, Cilantro, Red Onion, Radish, 2 Consomes with your choice of protein.\nProtein Choices: Birria, Asada, Chicken, Pastor, Veggies, Shrimp (+ $3.00)',
        price: '25.95',
    },
    {
        imageUrl: "https://redz-bucket.s3.amazonaws.com/SamplerPlatter.webp",
        title: 'Sampler Platter',
        description: '3 Taquitos De Papa, 3 Quesotacos, 3 Street Tacos, Consome.\nProtein Choices: Birria, Asada, Chicken, Pastor, Veggies, Shrimp (+ $3.00)',
        price: '32',
    },
    {
        imageUrl:"https://redz-bucket.s3.amazonaws.com/QuestotacoPlatter.webp",
        title: 'Quesotaco Platter',
        description: '10 Quesotacos, 2 Consomes with your choice of protein.\nProtein Choices: Birria, Asada, Chicken, Pastor, Veggies, Shrimp (+ $3.00)',
        price: '55',
    },
];

const settings: Settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3, // Default to 3 slides
    slidesToScroll: 1,
    arrows: false,
    autoplay: true, // Enable autoplay
    autoplaySpeed: 3000, // Speed for the autoplay (3000ms = 3 seconds)
    pauseOnHover: false, // Prevent pausing on hover
    responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
            },
        },
        {
            breakpoint: 600,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
            },
        },
    ],
};

const SignatureDishesCarousel: React.FC = () => {
    return (
        <Slider {...settings} className="text-center">
            {dishes.map((dish, index) => (
                 <a 
                 href="/order" >
                    <div key={index} className="relative p-4">
                    <ClippedImage imageUrl={dish.imageUrl} />
                    {/* SVG Label with Text Overlay */}
                    <div className="relative mt-4 flex justify-center">
                        <div className="relative inline-flex items-center px-10 py-8">
                            <SignatureDishLabel className="absolute inset-0 w-full h-full" />
                            <h3 className="relative z-10 text-white font-redsox text-3xl">
                                {dish.title}
                            </h3>
                        </div>
                    </div>
                    <p className="text-gray-700 mt-2 whitespace-pre-line font-myriad">{dish.description}</p>
                    <div className="flex items-baseline justify-center text-redz-red text-2xl font-nexa font-bold mt-4">
                        <DollarSign className="w-4 h-4 mr-1 -translate-y-1/3 translate-x-1/4" /> {/* Smaller Dollar Sign */}
                        {dish.price}
                    </div>
                </div>
                 </a>
            ))}
        </Slider>
    );
};

export default SignatureDishesCarousel;