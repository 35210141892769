import React from "react";
import { ReactComponent as SeparatorSvg } from '../../../assets/images/HomeVerticalSeperator.svg';

const RightSection: React.FC = () => (
    <div className="relative w-1/2 h-full hidden lg:block z-40">
        {/* Image covering the right half of the screen */}
        <div className="absolute inset-0 z-40">
            <img
                src={"https://redz-bucket.s3.amazonaws.com/TacosHome.webp"}
                alt="Tacos"
                className="h-full w-full object-cover z-40"
            />
        </div>
        {/* Separator positioned at the left edge of the image, with only y-overflow hidden */}
        <div className="absolute left-[-3.8em] bottom-0 h-full z-50 overflow-y-hidden">
            <SeparatorSvg className="w-[6em]" />
        </div>
    </div>
);

export default RightSection;