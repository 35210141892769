import React from 'react';
import { observer } from 'mobx-react-lite';
import DeliveryCostRow from './DeliveryCostRow';
import { cartStore } from '@redz/shared';

const PaymentSummary: React.FC = observer(() => {
  const {
    cartItems,
    cartSubtotal,
    cartTax,
    promoDiscount,
    existingPoints,
    tip,
    finalWithTip,
    finalBeforeTip,
    lineItemTotal,
    deliveryFee,
    deliveryType,
  } = cartStore;

  // Earn loyalty points: e.g. 1 point per dollar
  const pointsEarned = Math.floor(cartSubtotal);
  const newPointsBalance = existingPoints + pointsEarned;

  // 15% savings if user does delivery
  const directOrderSavings = finalBeforeTip * 0.15;

  return (
    <div className="border p-4 rounded">
      <h3 className="text-lg font-semibold mb-3">Your Order</h3>

      {/* 1) Render line items */}
      {cartItems.map(item => {
        const total = lineItemTotal(item);
        return (
          <div key={item.id} className="flex justify-between text-sm mb-1">
            <span>
              {item.quantity} × {item.menuItem.name}
            </span>
            <span>${total.toFixed(2)}</span>
          </div>
        );
      })}

      <hr className="my-2" />

      {/* 2) Subtotal */}
      <div className="flex justify-between text-sm">
        <span>Subtotal</span>
        <span>${cartSubtotal.toFixed(2)}</span>
      </div>

      {/* 3) Promo discount */}
      {promoDiscount > 0 && (
        <div className="flex justify-between text-sm text-green-700">
          <span>Promo Discount</span>
          <span>-${promoDiscount.toFixed(2)}</span>
        </div>
      )}

      {/* 4) Tax */}
      <div className="flex justify-between text-sm">
        <span>Tax</span>
        <span>${cartTax.toFixed(2)}</span>
      </div>

      {/* 5) Delivery row */}
      {deliveryFee > 0 && deliveryType === 'delivery' && (
        <div className="flex justify-between text-sm">
          <span>Delivery</span>
          <DeliveryCostRow />
        </div>
      )}

      {/* 6) Tip (only show if delivery) */}
      {deliveryType === 'delivery' && tip > 0 && (
        <div className="flex justify-between text-sm">
          <span>Driver Tip</span>
          <span>${tip.toFixed(2)}</span>
        </div>
      )}

      <hr className="my-2" />

      {/* 7) Final total */}
      <div className="flex justify-between text-base font-bold items-center mt-2">
        <span>Total</span>
        <span>${finalWithTip.toFixed(2)}</span>
      </div>

      {/* 8) "You saved 15%" banner for delivery */}
      {deliveryType === 'delivery' && (
        <div className="mt-3 bg-blue-50 p-2 text-sm border border-blue-100 rounded">
          You saved <strong>${directOrderSavings.toFixed(2)}</strong> by ordering with us directly!
        </div>
      )}

      {/* 9) Points info */}
      <div className="mt-3 bg-yellow-50 p-2 text-sm rounded">
        <p>
          You earned <strong>{pointsEarned}</strong> points on this order.
        </p>
        <p>
          Your new balance: <strong>{newPointsBalance}</strong> points!
        </p>
      </div>
    </div>
  );
});

export default PaymentSummary;