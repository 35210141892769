// CateringPackages.tsx
import React from "react";
import SectionTitle from "../Menu/SectionTitle";
import Sombrero from '../../assets/images/Sombrero.svg';

// The marketing data for quick display
const PACKAGES = [
  {
    id: "street-taco",
    name: "Street Taco Fiesta",
    description: `
      Classic taco bar featuring Street Tacos with cilantro, onion, and up to 3 proteins.
      Includes Mexican rice, refried beans, salsa, plates, and utensils.
    `,
    priceTiers: [
      { minGuests: 40, maxGuests: 59, pricePerPerson: 25 },
      { minGuests: 60, maxGuests: 79, pricePerPerson: 23 },
      { minGuests: 80, maxGuests: 99, pricePerPerson: 21 },
      { minGuests: 100,              pricePerPerson: 19 },
    ],
    background: "bg-redz-white",
    imageSrc: "https://redz-bucket.s3.amazonaws.com/BlackTaco.webp",
  },
  {
    id: "quesotaco",
    name: "Quesotaco Fiesta",
    description: `
      Cheese-crusted Birria Quesotacos served with consomé, plus rice, beans, and salsas.
      You can add a secondary taco option (chicken or veggie) if desired.
    `,
    priceTiers: [
      { minGuests: 40, maxGuests: 59, pricePerPerson: 29 },
      { minGuests: 60, maxGuests: 79, pricePerPerson: 27 },
      { minGuests: 80, maxGuests: 99, pricePerPerson: 25 },
      { minGuests: 100,              pricePerPerson: 23 },
    ],
    background: "bg-black",
    imageSrc: Sombrero,
  },
  {
    id: "premium",
    name: "Birria Feast",
    description: `
      Our highest-level catering, featuring premium plated Birria with generous servings of consomé, \
      tortillas, rice, and beans. The more you pay per person, the more appetizers, entrées, and dessert options you can select. 
    `,
    priceTiers: [
      { minGuests: 40, pricePerPerson: 35 }
    ],
    background: "bg-redz-white",
    imageSrc: "https://redz-bucket.s3.amazonaws.com/PremiumPackage.webp",
  },
];

// 20% service charge
const SERVICE_CHARGE_RATE = 0.2;

// Data from the Birria Feast subPackages (so the “Curate Your Event” section can match)
const BIRRIA_FEAST_APPETIZERS = [
  "Taquitos De Papa",
  "Nachos",
  "Quesadillas",
  "Pizzadilla",
  "Yona Loaded Fries",
  "Chips and Guacamole",
];

const BIRRIA_FEAST_ENTREES = [
  "Street Taco Plate",
  "Traditional Birria Plate",
  "Yona Special",
  "Burrito",
  "Sampler Platter",
  "Tostada Bowl",
  "Birria Ramen",
];

const BIRRIA_FEAST_DESSERTS = [
  "Churro",
  "Churros con McConnels Ice Cream",
  "Choco Flan",
  "Tres Leches Cake",
];

const CateringPackages: React.FC = () => {
  return (
    <div className="w-full">
      {PACKAGES.map((pkg, idx) => (
        <section
          key={pkg.id}
          className={`${pkg.background} ${idx === 1 ? "text-white" : "text-black"} py-12 px-4`}
        >
          <div className="max-w-5xl mx-auto flex flex-col items-center">
            {/* 1) Top: Package Title, Image, Tagline */}
            <SectionTitle
              title={pkg.name}
              black={idx !== 1}
              TacoColor={idx === 1 ? "white" : "black"}
              alternate
            />

            <img
              src={pkg.imageSrc}
              alt={pkg.name}
              className="w-36 h-auto mb-6"
            />

            {/* Tagline (subheading) */}
            <h3
              className={`
                font-redsox text-xl text-center mb-2 
                ${idx === 1 ? "text-redz-red" : "text-redz-red"}
              `}
            >
              {idx === 0 && "Classic Taco Bar for Your Event"}
              {idx === 1 && "Cheesy Birria Quesotacos with a Twist"}
              {idx === 2 && "Plated Birria Experience at Its Finest"}
            </h3>

            {/* Description */}
            <p
              className={`
                font-myriad text-base text-balance text-center max-w-2xl mb-6 
                ${idx === 1 ? "text-white" : "text-black"}
              `}
              style={{ whiteSpace: "pre-line" }}
            >
              {pkg.description.trim()}
            </p>

            {/* Price Tiers */}
            <h4 className="font-redsox text-lg mb-3 text-redz-red">
              Price Tiers (per person)
            </h4>
            <div className="font-myriad text-base mb-6 space-y-2">
              {pkg.priceTiers.map((tier, tIndex) => {
                const guestRange = tier.maxGuests
                  ? `${tier.minGuests}–${tier.maxGuests} guests`
                  : `${tier.minGuests}+ guests`;
                return (
                  <p key={tIndex}>
                    <strong>{guestRange}:</strong>{" "}
                    ${tier.pricePerPerson.toFixed(2)} / person
                  </p>
                );
              })}
            </div>

            <p className="font-myriad text-sm italic mb-10 text-gray-700">
              * A {(SERVICE_CHARGE_RATE * 100).toFixed(0)}% service charge will be added.
            </p>
          </div>

          {/* 2) Additional Info in 3 Columns */}
          <div className={`max-w-5xl mx-auto px-4 md:grid md:grid-cols-3 gap-6 ${idx === 1 ? "text-white" : "text-black"}`}>
            {/* Column 1: Service Provided */}
            <div className="mb-8">
              <h5 className="font-redsox text-lg mb-2 text-redz-red">Service Provided</h5>
              <p className="font-myriad text-base leading-relaxed">
                {idx === 0 &&
                  `Buffet-style setup with on-site staff keeping tacos hot & fresh. 
                   We provide disposable plates, napkins, and utensils.`
                }
                {idx === 1 &&
                  `Elevated buffet or live station for Quesotacos, 
                   with on-site staff preparing them to order. Show-stopping cheesy birria.`
                }
                {idx === 2 &&
                  `Plated Birria feast for a more formal experience, complete with staff
                   delivering plates to each guest. Presentation guaranteed.`
                }
              </p>
            </div>

            {/* Column 2: Meal Options */}
            <div className="mb-8">
              <h5 className="font-redsox text-lg mb-2 text-redz-red">Meal Options</h5>
              <p className="font-myriad text-base leading-relaxed">
                {idx === 0 &&
                  `Up to 3 proteins (Birria, Asada, Chicken, etc.) with rice, beans, salsas, & toppings.`
                }
                {idx === 1 &&
                  `Cheese-crusted Birria Quesotacos + optional second taco type (veggie or chicken). 
                   Includes rice, beans, consomé, & salsas.`
                }
                {idx === 2 &&
                  `Generous Birria portions, plus optional second protein for variety. Rice, beans,
                   and house salsas included. (Tacos or plated style, as needed.)`
                }
              </p>
            </div>

            {/* Column 3: Coordination */}
            <div className="mb-8">
              <h5 className="font-redsox text-lg mb-2 text-redz-red">Coordination</h5>
              <p className="font-myriad text-base leading-relaxed">
                {idx === 0 &&
                  `We arrive ~1 hour before service to set up the buffet. 
                   Serving guests for up to 2 hours (or as arranged).`
                }
                {idx === 1 &&
                  `Our team handles on-site cooking & plating of Quesotacos. 
                   We'll coordinate with your venue for a smooth experience.`
                }
                {idx === 2 &&
                  `We coordinate a table service or plating line to deliver each Birria plate. 
                   We'll work with your venue schedule to ensure a seamless flow.`
                }
              </p>
            </div>
          </div>
        </section>
      ))}

      {/* "Curate Your Event" Section: updated to match the Birria Feast subPackages */}
      <section className="bg-redz-white py-12 px-4">
        <div className="max-w-5xl mx-auto text-center mb-8">
          <h2 className="font-redsox text-2xl text-black mb-4">
            Curate Your Event Menu
          </h2>
          <p className="font-myriad text-base text-black">
            For our Birria Feast (or fully custom events), you can pick from a range
            of appetizers, entrées, desserts, and drinks. Our team will coordinate
            everything to ensure a smooth service. Additional coordination fees
            may apply for fully custom menus.
          </p>
        </div>

        {/* 4-Column layout on md+ screens */}
        <div className="max-w-5xl mx-auto grid grid-cols-1 md:grid-cols-4 gap-6">
          {/* Appetizers */}
          <div className="bg-white rounded p-4 shadow text-black">
            <h3 className="font-redsox text-lg text-redz-red mb-2">
              Appetizers
            </h3>
            <ul className="list-disc list-inside font-myriad text-base space-y-1">
              {BIRRIA_FEAST_APPETIZERS.map((app, i) => (
                <li key={i}>{app}</li>
              ))}
            </ul>
          </div>

          {/* Entrees */}
          <div className="bg-white rounded p-4 shadow text-black">
            <h3 className="font-redsox text-lg text-redz-red mb-2">
              Entrees
            </h3>
            <ul className="list-disc list-inside font-myriad text-base space-y-1">
              {BIRRIA_FEAST_ENTREES.map((entree, i) => (
                <li key={i}>{entree}</li>
              ))}
            </ul>
          </div>

          {/* Desserts */}
          <div className="bg-white rounded p-4 shadow text-black">
            <h3 className="font-redsox text-lg text-redz-red mb-2">
              Desserts
            </h3>
            <ul className="list-disc list-inside font-myriad text-base space-y-1">
              {BIRRIA_FEAST_DESSERTS.map((dessert, i) => (
                <li key={i}>{dessert}</li>
              ))}
            </ul>
          </div>

          {/* Drinks */}
          <div className="bg-white rounded p-4 shadow text-black">
            <h3 className="font-redsox text-lg text-redz-red mb-2">
              Drinks
            </h3>
            <ul className="list-disc list-inside font-myriad text-base space-y-1">
              <li>Aguas Frescas</li>
              <li>Horchata</li>
              <li>Mexican Sodas</li>
            </ul>
          </div>
        </div>
      </section>
    </div>
  );
};

export default CateringPackages;