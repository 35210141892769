// DeliveryCostRow.tsx
import React from "react";
import { observer } from "mobx-react-lite";
import { cartStore } from "@redz/shared";

const DeliveryCostRow: React.FC = observer(() => {
  const { deliveryFee, restaurantDiscount } = cartStore;
  
  // net = final cost after discount
  const net = Math.max(
    0,
    deliveryFee - (Number.isFinite(restaurantDiscount) ? restaurantDiscount : deliveryFee)
  );
  const saved = deliveryFee - net;

  // If no delivery fee at all
  if (deliveryFee <= 0) {
    return (
      <>
        <span className="float-right">$0.00</span>
        {/* No savings text or anything else */}
      </>
    );
  }

  // If no restaurant discount
  if (!restaurantDiscount || restaurantDiscount <= 0) {
    return (
      <>
        <span className="float-right">${deliveryFee.toFixed(2)}</span>
      </>
    );
  }

  // If discount covers entire fee (or is Infinity)
  if (restaurantDiscount >= deliveryFee || !Number.isFinite(restaurantDiscount)) {
    return (
      <>
        <span>
          <s className="text-gray-500 mr-1">${deliveryFee.toFixed(2)}</s>
          $0.00
        </span>
        <p className="text-xs text-green-700 mt-0.5">
          You saved ${deliveryFee.toFixed(2)}!
        </p>
      </>
    );
  }

  // partial discount scenario
  return (
    <>
      <span>
        <s className="text-gray-500 mr-1">${deliveryFee.toFixed(2)}</s>
        ${net.toFixed(2)}
      </span>
      <p className="text-xs text-green-700 mt-0.5">
        You saved ${saved.toFixed(2)}!
      </p>
    </>
  );
});

export default DeliveryCostRow;