// src/components/ContactInfoFields.tsx
import React from "react";

interface ContactInfoFieldsProps {
  name: string;
  setName: (val: string) => void;
  email: string;
  setEmail: (val: string) => void;
  phone: string;
  setPhone: (val: string) => void;
  marketingOptIn: boolean;
  setMarketingOptIn: (b: boolean) => void;

  // Optional label or heading, e.g. "Contact Info (Pickup)" 
  // so you can customize. Or omit if you just want the fields:
  heading?: string; 
}

const ContactInfoFields: React.FC<ContactInfoFieldsProps> = (props) => {
  const {
    name,
    setName,
    email,
    setEmail,
    phone,
    setPhone,
    marketingOptIn,
    setMarketingOptIn,
    heading,
  } = props;

  return (
    <div className="mb-4 space-y-2">
      {heading && <h4 className="text-md font-semibold">{heading}</h4>}

      {/* Name */}
      <div>
        <label className="block text-sm font-semibold">Name</label>
        <input
          value={name}
          onChange={(e) => setName(e.target.value)}
          className="border p-2 w-full text-black"
          placeholder="John Smith"
        />
      </div>

      {/* Email */}
      <div>
        <label className="block text-sm font-semibold">Email</label>
        <input
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          className="border p-2 w-full text-black"
          placeholder="john@example.com"
        />
      </div>

      {/* Phone */}
      <div>
        <label className="block text-sm font-semibold">Phone</label>
        <input
          value={phone}
          onChange={(e) => setPhone(e.target.value)}
          className="border p-2 w-full text-black"
          placeholder="(805) 123-4567"
        />
      </div>

      {/* Marketing Opt-in */}
      <div className="flex items-center space-x-2">
        <input
          type="checkbox"
          checked={marketingOptIn}
          onChange={(e) => setMarketingOptIn(e.target.checked)}
          id="marketingOptIn"
        />
        <label htmlFor="marketingOptIn" className="text-sm font-semibold">
          Let Yona Redz email me special offers
        </label>
      </div>
    </div>
  );
};

export default ContactInfoFields;