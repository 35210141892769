import React from 'react';
import { observer } from 'mobx-react-lite';
import { toast } from 'react-toastify';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';
import { cartStore } from '@redz/shared';
import { motion } from 'framer-motion';
import ContactInfoFields from './ContactInfoFields';

interface DeliverySectionProps {
  // Contact info fields
  name: string;
  setName: (val: string) => void;
  email: string;
  setEmail: (val: string) => void;
  phone: string;
  setPhone: (val: string) => void;
  marketingOptIn: boolean;
  setMarketingOptIn: (b: boolean) => void;
  onContinue: () => void;
}

// Constants for distance calculation
const PICKUP_LAT = 34.42409;
const PICKUP_LNG = -119.70546;

const DeliverySection: React.FC<DeliverySectionProps> = observer((props) => {
  const {
    name,
    setName,
    email,
    setEmail,
    phone,
    setPhone,
    marketingOptIn,
    setMarketingOptIn,
    onContinue,
  } = props;

  // Local address text
  const [localAddress, setLocalAddress] = React.useState("");
  
  // Local lat/lng + distance
  const [localLat, setLocalLat] = React.useState<number | null>(null);
  const [localLng, setLocalLng] = React.useState<number | null>(null);
  const [localDistance, setLocalDistance] = React.useState(0);

  // Track which tip radio is selected
  const [tipOption, setTipOption] = React.useState<'optionA' | 'optionB' | 'optionC' | 'other'>('optionA');

  // Haversine formula: distance in miles
  function getDistanceMiles(
    lat1: number,
    lon1: number,
    lat2: number | undefined,
    lon2: number | undefined
  ): number {
    if (!lat2 || !lon2) return 0;
    const R = 3958.8; // Earth radius in miles
    const toRad = (val: number) => (val * Math.PI) / 180;
    const dLat = toRad(lat2 - lat1);
    const dLon = toRad(lon2 - lon1);

    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(toRad(lat1)) *
        Math.cos(toRad(lat2)) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    return R * c;
  }

  // Recompute local distance whenever lat/lng changes
  React.useEffect(() => {
    if (localLat != null && localLng != null) {
      const miles = getDistanceMiles(PICKUP_LAT, PICKUP_LNG, localLat, localLng);
      setLocalDistance(miles);
      cartStore.setDistance(miles);
    } else {
      setLocalDistance(0);
      cartStore.setDistance(0);
    }
  }, [localLat, localLng]);

  // -------------------------
  // VALIDATION
  // -------------------------
  function validateFields(): boolean {
    const trimmedName = name.trim();
    if (!trimmedName) {
      toast.error("Please enter your name.");
      return false;
    }

    // Basic email check
    const trimmedEmail = email.trim();
    if (!trimmedEmail || !trimmedEmail.includes("@")) {
      toast.error("Please enter a valid email address.");
      return false;
    }

    // Strip non-digits from phone, must be at least 10 digits
    const phoneDigits = phone.replace(/\D+/g, "");
    if (phoneDigits.length < 10) {
      toast.error("Please enter a valid 10-digit phone number.");
      return false;
    }

    // Address must be set
    if (!localAddress.trim()) {
      toast.error("Please enter your delivery address.");
      return false;
    }

    // Confirm we got a delivery quote from the server
    if (!cartStore.deliveryQuoteId) {
      toast.error("Please select a valid address and get a delivery estimate first.");
      return false;
    }

    // If everything is valid, store the cleaned phone if desired
    setPhone(phoneDigits);

    return true;
  }

  function handleContinueClick() {
    if (validateFields()) {
      onContinue();
    }
  }

  // -------------------------
  // TIP LOGIC (STORE-BASED)
  // -------------------------
  function handleTipOptionChange(
    option: 'optionA' | 'optionB' | 'optionC' | 'other'
  ) {
    setTipOption(option);
    if (option === 'optionA') {
      cartStore.setTip(parseFloat(cartStore.basicTip.toFixed(2)));
    } else if (option === 'optionB') {
      cartStore.setTip(parseFloat(cartStore.plusTip.toFixed(2)));
    } else if (option === 'optionC') {
      cartStore.setTip(parseFloat(cartStore.superTip.toFixed(2)));
    } else {
      // 'other' => custom user input
      cartStore.setTip(0);
    }
  }

  function handleCustomTipChange(val: number) {
    setTipOption('other');
    cartStore.setTip(val);
  }

  // -------------------------
  // ADDRESS SELECTION
  // -------------------------
  async function handleAddressSelect(selected: string) {
    setLocalAddress(selected);
    try {
      const results = await geocodeByAddress(selected);
      if (!results[0]) {
        toast.error("Could not parse that address, please try again.");
        cartStore.setDeliveryFee(0);
        cartStore.deliveryQuoteId = null;
        return;
      }

      // 1) Parse address for the store (street/city/state/zip)
      cartStore.setGoogleAddressComponents(results[0].address_components);

      // Validate that the store has a complete address
      const { deliveryStreet, deliveryCity, deliveryState, deliveryZip } = cartStore;
      if (!deliveryStreet || !deliveryCity || !deliveryState || !deliveryZip) {
        toast.error("Incomplete address. Must have street, city, state, and zip.");
        cartStore.setDeliveryFee(0);
        cartStore.deliveryQuoteId = null;
        return;
      }

      // 2) Convert to lat/lng for local distance
      const latLng = await getLatLng(results[0]);
      setLocalLat(latLng.lat);
      setLocalLng(latLng.lng);

      // 3) Fetch delivery estimate from the server
      try {
        await cartStore.fetchDeliveryEstimate();
      } catch (err: any) {
        console.error("Error from /api/estimate-delivery:", err);
        const serverMsg = err?.response?.data?.message || err?.message || "Delivery estimate failed";
        toast.error(serverMsg);
        cartStore.deliveryQuoteId = null;
      }

    } catch (geoErr) {
      console.error("Geocode error:", geoErr);
      toast.error("Unable to geocode address. Please check it again.");
      cartStore.setDeliveryFee(0);
      cartStore.deliveryQuoteId = null;
    }
  }

  // -------------------------
  // RENDER
  // -------------------------
  const phoneDigits = phone.replace(/\D+/g, "");
  const isFormValid =
    !!name.trim() &&
    !!email.trim() &&
    email.trim().includes("@") &&
    phoneDigits.length >= 10 &&
    !!localAddress.trim() &&
    !!cartStore.deliveryQuoteId; // indicates we have a successful quote

  return (
    <div className="space-y-4 ">
      <h3 className="text-lg font-semibold mb-2">Delivery & Contact Info</h3>

      {/* Contact info fields */}
      <ContactInfoFields
        name={name || ""}
        setName={(val) => setName?.(val)}
        email={email || ""}
        setEmail={(val) => setEmail?.(val)}
        phone={phone || ""}
        setPhone={(val) => setPhone?.(val)}
        marketingOptIn={marketingOptIn || false}
        setMarketingOptIn={(b) => setMarketingOptIn?.(b)}
        heading="Contact Info (Delivery)"
      />

      {/* Delivery Address input */}
      <div>
        <label className="block text-sm font-semibold">Delivery Address</label>
        <PlacesAutocomplete
          value={localAddress}
          onChange={setLocalAddress}
          onSelect={handleAddressSelect}
        >
          {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
            <div>
              <input
                {...getInputProps({ placeholder: "Enter your address" })}
                className="border p-2 w-full text-black"
              />
              {suggestions?.length > 0 && (
                <div className="border bg-white text-black">
                  {loading && <div className="p-2">Loading...</div>}
                  {suggestions.map((sugg) => {
                    const style = sugg.active
                      ? { backgroundColor: "#f0f0f0", cursor: "pointer" }
                      : { backgroundColor: "#fff", cursor: "pointer" };
                    return (
                      <>
                      <div
                        {...getSuggestionItemProps(sugg, { style })}
                        key={sugg.placeId}
                        className="p-2 hover:bg-gray-100"
                      >
                        {sugg.description}
                      </div>
                      <hr
                          className="h-px border-t-0 bg-transparent bg-gradient-to-r from-transparent via-neutral-500 to-transparent opacity-25 dark:opacity-100"
                        />
                      </>
                    );
                  })}
                </div>
              )}
            </div>
          )}
        </PlacesAutocomplete>
      </div>

      {/* Delivery Fee & Restaurant Discount (STORE) */}
      {cartStore.deliveryFee > 0 && (
        <p className="text-sm font-medium">
          Delivery cost:{" "}
          <strong>
            {
              (() => {
                const newCost = cartStore.deliveryFee - cartStore.restaurantDiscount;
                const isFullCoverage = !Number.isFinite(newCost);
                return( 
                <>
                {isFullCoverage? 'FREE' : newCost.toFixed(2)}
                </>)
              })()
            }
          </strong>
          {cartStore.restaurantDiscount > 0 && (
            <>
              <span className="line-through text-red-600 ml-2">
                ${cartStore.deliveryFee.toFixed(2)}
              </span>

              {/* Compute `coverage` as either the discount or the entire delivery fee. */}
              {/* If the discount is Infinity, that means the restaurant is covering the full cost. */}
              <span className="text-green-600 ml-2">
                {(() => {
                  const isFullCoverage = !Number.isFinite(cartStore.restaurantDiscount);
                  // Alternatively: `cartStore.restaurantDiscount === Infinity`
                  const coverage = isFullCoverage 
                    ? cartStore.deliveryFee 
                    : cartStore.restaurantDiscount;

                  return `(We cover $${coverage.toFixed(2)})`;
                })()}
              </span>
            </>
          )}
        </p>
      )}

      {/* Local distance display */}
      {localDistance > 0 && (
        <p className="text-xs text-gray-600">
          Distance from store: {localDistance.toFixed(2)} miles
        </p>
      )}

      {/* Driver Tip selection (STORE) */}
      <div>
        <label className="block text-sm font-semibold mb-1">Driver Tip</label>
        {localDistance > 0 ? (
          <div className="flex flex-col space-y-2 text-sm">
            <label className="flex items-center space-x-2">
              <input
                type="radio"
                name="tipOption"
                checked={tipOption === 'optionA'}
                onChange={() => handleTipOptionChange('optionA')}
              />
              <span>${cartStore.basicTip.toFixed(2)} (Basic)</span>
            </label>

            <label className="flex items-center space-x-2">
              <input
                type="radio"
                name="tipOption"
                checked={tipOption === 'optionB'}
                onChange={() => handleTipOptionChange('optionB')}
              />
              <span>${cartStore.plusTip.toFixed(2)} (Generous)</span>
            </label>

            <label className="flex items-center space-x-2">
              <input
                type="radio"
                name="tipOption"
                checked={tipOption === 'optionC'}
                onChange={() => handleTipOptionChange('optionC')}
              />
              <span>${cartStore.superTip.toFixed(2)} (Super Generous)</span>
            </label>

            <label className="flex items-center space-x-2">
              <input
                type="radio"
                name="tipOption"
                checked={tipOption === 'other'}
                onChange={() => handleTipOptionChange('other')}
              />
              <span>Other:</span>
              {tipOption === 'other' && (
                <input
                  type="number"
                  className="border p-1 w-20"
                  min="0"
                  step="0.01"
                  value={cartStore.tip.toString()}
                  onChange={(e) => handleCustomTipChange(parseFloat(e.target.value) || 0)}
                />
              )}
            </label>
          </div>
        ) : (
          // If distance is 0 (i.e. user hasn't entered an address), just show a basic input
          <div className="flex space-x-2">
            <input
              type="number"
              min="0"
              step="0.01"
              className="border p-2 w-20"
              value={cartStore.tip.toString()}
              onChange={(e) => handleCustomTipChange(parseFloat(e.target.value) || 0)}
            />
            <span className="text-xs text-gray-600 self-center">
              Enter a tip amount
            </span>
          </div>
        )}
        
        {/* Continue Button */}
        <div className="mt-4">
          <motion.button
            onClick={handleContinueClick}
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            disabled={!isFormValid}
            className="disabled:pointer-events-none disabled:bg-gray-300 bg-red-500 text-white py-2 px-4 rounded w-full mt-4"
          >
            Review &amp; Continue
          </motion.button>
        </div>
      </div>
    </div>
  );
});

export default DeliverySection;