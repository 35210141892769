import { makeAutoObservable } from 'mobx';
import { toast } from 'react-toastify';
import { API } from '@redz/shared';

import {
  Menu,
  MenuItem,
} from '@redz/shared';

/**
 * We'll keep a single store-level array of "flattened" MenuItems
 * from the menu named "Yona Redz Menu".
 */
class MenuStore {
  items: MenuItem[] = [];
  loading = false;
  error: string | null = null;

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  /**
   * Fetches an array of Menu objects from /api/menus/.
   * We look specifically for the one named "Yona Redz Menu".
   * Then we flatten categories + subcategories => this.items.
   */
  async fetchMenu() {
    this.loading = true;
    this.error = null;

    try {
      // GET /api/menus => returns Menu[]
      const response = await API.get<Menu[]>('/api/menus/');
      const menus = response.data;

      if (!Array.isArray(menus) || menus.length === 0) {
        throw new Error('No menus returned from the API');
      }

      // Find the menu named "Yona Redz Menu"
      const redzMenu = menus.find(m => m.name === 'Yona Redz Menu');
      if (!redzMenu) {
        throw new Error('Could not find "Yona Redz Menu" among returned menus.');
      }

      // Flatten the menu's items
      const flattenedItems: MenuItem[] = [];

      // For each Category
      redzMenu.categories.forEach(cat => {
        // Flatten top-level menu_items
        cat.menu_items.forEach(item => {
          flattenedItems.push(
            this.buildFlattenedItem(item, cat.name, undefined /* no subcat */)
          );
        });

        // Flatten subcategory items
        cat.subcategories.forEach(subcat => {
          subcat.menu_items.forEach(item => {
            flattenedItems.push(
              this.buildFlattenedItem(item, cat.name, subcat.name)
            );
          });
        });
      });

      this.items = flattenedItems;
    } catch (err: any) {
      console.error('Error fetching "Yona Redz Menu":', err);
      this.error = err.message || 'Failed to fetch "Yona Redz Menu".';
      toast.error(this.error);
    } finally {
      this.loading = false;
    }
  }

  /**
   * Helper: create a flattened MenuItem while parsing numeric fields
   * (base_price).
   */
  private buildFlattenedItem(
    item: MenuItem,
    categoryName: string,
    subcategoryName?: string
  ): MenuItem {
    return {
      ...item,
      base_price: parsePrice(item.base_price),
      // For display convenience, we store the category & subcategory names
      categoryName,
      subcategoryName,
    };
  }

  // Example computed getters by categoryName
  get weekendSpecialItems() {
    return this.items.filter(item => item.categoryName === 'Weekend Special');
  }
  get specialtiesItems() {
    return this.items.filter(item => item.categoryName === 'Specialties');
  }
  get singleItems() {
    return this.items.filter(item => item.categoryName === 'Single Items');
  }
  get kidsMenuItems() {
    return this.items.filter(item => item.categoryName === 'Kids Menu');
  }
  get sidesItems() {
    return this.items.filter(item => item.categoryName === 'Sides');
  }
  get drinksItems() {
    return this.items.filter(item => item.categoryName === 'Drinks');
  }
  get happyHourItems() {
    return this.items.filter(item => item.categoryName === 'Happy Hour Specials');
  }
  get tacoTuesdayItems() {
    return this.items.filter(item => item.categoryName === 'Taco Tuesday');
  }
  get localSpecialsItems() {
    return this.items.filter(item => item.categoryName === 'Local Specials');
  }
}

// Helper: parse base_price (or any numeric field) from string to number
function parsePrice(price: any): number | undefined {
  if (price == null) return undefined;
  if (typeof price === 'number') return price;
  const parsed = parseFloat(price);
  return isNaN(parsed) ? undefined : parsed;
}



const menuStore = new MenuStore();
export default menuStore;