// src/components/Catering/StepContactAndEvent.tsx
import React from "react";
import { observer } from "mobx-react-lite";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import cateringStore from "../../../stores/CateringStore";

interface StepContactAndEventProps {
  onInquire: () => void;
  onNext: () => void;
}

const StepContactAndEvent: React.FC<StepContactAndEventProps> = observer(({ onInquire, onNext }) => {
  const {
    name, setName,
    email, setEmail,
    phone, setPhone,
    eventDate, setEventDate,
    eventType, setEventType,
    serviceHours, setServiceHours,
  } = cateringStore;

  const inputClass = "border p-2 rounded w-full text-black";

  return (
    <div className="my-8 bg-white p-4 rounded shadow">
      <h2 className="text-xl font-nexa mb-4">1) Contact & Event Info</h2>

      {/* Contact */}
      <div className="mb-4">
        <label className="block text-sm font-semibold">Name</label>
        <input
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
          className={inputClass}
          required
        />
      </div>
      <div className="mb-4">
        <label className="block text-sm font-semibold">Email</label>
        <input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          className={inputClass}
          required
        />
      </div>
      <div className="mb-4">
        <label className="block text-sm font-semibold">Phone</label>
        <input
          type="tel"
          value={phone}
          onChange={(e) => setPhone(e.target.value)}
          className={inputClass}
        />
      </div>

      {/* Event Date */}
      <div className="mb-4">
        <label className="block text-sm font-semibold">Date of Event</label>
        <DatePicker
          selected={eventDate || null}
          onChange={(date) => setEventDate(date || null)}
          className={inputClass}
          isClearable
          placeholderText="Select a date"
        />
      </div>

      {/* Event Type */}
      <div className="mb-4">
        <label className="block text-sm font-semibold">Event Type</label>
        <input
          type="text"
          value={eventType}
          onChange={(e) => setEventType(e.target.value)}
          className={inputClass}
          placeholder="Wedding, Corporate, etc."
        />
      </div>

      {/* Service Hours */}
      <div className="mb-4">
        <label className="block text-sm font-semibold">Hours of Service</label>
        <input
          type="number"
          value={serviceHours ?? ""}
          onChange={(e) => {
            const val = e.target.value;
            if (!val) {
              setServiceHours(null);
            } else {
              const num = parseInt(val, 10);
              setServiceHours(isNaN(num) ? null : num);
            }
          }}
          className={inputClass}
          min={0}
          placeholder="e.g. 4"
        />
      </div>

      {/* Buttons */}
      <div className="flex space-x-4">
        <button
          type="button"
          onClick={onInquire}
          className="bg-gray-600 text-white px-4 py-2 rounded"
        >
          Inquire Only
        </button>
        <button
          type="button"
          onClick={onNext}
          className="bg-red-600 text-white px-4 py-2 rounded"
        >
          Next (Review)
        </button>
      </div>
    </div>
  );
});

export default StepContactAndEvent;