import React from 'react';
import { ReactComponent as HeroSignatureDishDivider } from '../../assets/images/Hero-signature-dish-divider.svg';

const CateringDividerSection: React.FC = () => {
  return (
    <div className="bg-redz-white ">
      {/* SVG overlapping the previous section by negative margin */}
      <div className="relative top-[-3vw] w-full right-24 md:right-36 z-20">
        <HeroSignatureDishDivider className="w-[120vw] h-auto" />
      </div>

      {/* Centered text block below the divider */}
      <div className="max-w-[900px] mx-auto px-4 text-center pb-8">
        <p className="font-redsox text-redz-red text-xl sm:text-2xl md:text-3xl leading-normal text-pretty">
          Redz offers a variety of catering packages to suit events of all sizes. Our packages are 
          designed to bring the authentic flavors of Redz to your event, with a range of options to 
          satisfy every guest.
        </p>
      </div>
    </div>
  );
};

export default CateringDividerSection;