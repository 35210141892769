// CateringPage.tsx (example)
import React from 'react';
import CateringHero from './CateringHero';
import CateringDetails from './CateringDetails';
import CateringDividerSection from './CateringDividerSection';
import CateringPackages from './CateringPackages';

const CateringPage: React.FC = () => {
  return (
    <main>
      <CateringHero />
      <CateringDividerSection />
      <CateringPackages />
      {/* Additional sections or content after the hero */}
      <CateringDetails/>
    </main>
  );
};

export default CateringPage;