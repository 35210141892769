import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';

import DeliverySection from './DeliverySection';
import PaymentSection from './PaymentSection';
import { cartStore } from '@redz/shared';

interface CheckoutModalProps {
  isOpen: boolean;
  onClose: () => void;
  restaurantDiscount: number;
}

const CheckoutModal: React.FC<CheckoutModalProps> = observer(
  ({ isOpen, onClose }) => {
    const { deliveryType } = cartStore;

    const [phase, setPhase] = useState<'delivery' | 'payment'>(
      deliveryType === 'delivery' ? 'delivery' : 'payment'
    );

    useEffect(() => {
      setPhase(deliveryType === 'delivery' ? 'delivery' : 'payment');
    }, [deliveryType]);

    // Basic user info states
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [marketingOptIn, setMarketingOptIn] = useState(true);

    if (!isOpen) return null;

    return (
      <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center">
        {/* The outer modal box. Notice the height and flex setup. */}
        <div className="relative w-full max-w-md h-[90vh] bg-white rounded shadow-lg flex flex-col">
          {/* Close button is absolutely positioned at the top-right so it never scrolls away */}
          <button
            onClick={onClose}
            className="absolute top-3 right-3 text-gray-500 hover:text-gray-800"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>

          {/* Content area. We add overflow-y-auto so it can scroll if it's too tall. */}
          <div className="flex-1 overflow-y-auto p-6 pt-10">
            {/* Title (optional). If you need the title always visible, 
                you can make a small sticky header inside. 
                For now, let's just put it at the top. */}
            <h2 className="text-xl font-semibold mb-4">Checkout</h2>

            {/* Show either the DeliverySection or PaymentSection */}
            {deliveryType === 'delivery' && phase === 'delivery' && (
              <DeliverySection
                name={name}
                setName={setName}
                email={email}
                setEmail={setEmail}
                phone={phone}
                setPhone={setPhone}
                marketingOptIn={marketingOptIn}
                setMarketingOptIn={setMarketingOptIn}
                onContinue={() => setPhase('payment')}
              />
            )}

            {(deliveryType === 'pickup' || phase === 'payment') && (
              <PaymentSection
                showContactFields={deliveryType === 'pickup'}
                name={name}
                email={email}
                phone={phone}
                marketingOptIn={marketingOptIn}
                setName={setName}
                setEmail={setEmail}
                setPhone={setPhone}
                setMarketingOptIn={setMarketingOptIn}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
);

export default CheckoutModal;