// PackageSelection.tsx

import React from 'react';
import { observer } from 'mobx-react-lite';
import cateringStore from '../../../stores/CateringStore';

// Import the real interface
import type { CateringPackage } from '../../../stores/CateringStore';

// No local props needed now
const PackageSelection: React.FC = observer(() => {
  const {
    packages,
    selectedPackageSlug,
    setSelectedPackageSlug,
    selectedUpgradeIndex,
    setSelectedUpgradeIndex,
  } = cateringStore;

  return (
    <div className="my-8">
      <h2 className="text-2xl font-nexa mb-4">1) Choose a Package</h2>

      <div className="space-y-5">
        {packages.map((pkg) => {
          const isSelected = pkg.slug_id === selectedPackageSlug;
          const isPremium = !!pkg.upgradeLevels; // or any other logic

          return (
            <label
              key={pkg.slug_id}
              className={`
                block border border-gray-300 rounded p-4 cursor-pointer
                transition hover:border-red-500
                ${isSelected ? 'border-red-500' : ''}
              `}
            >
              {/* Package-level radio button */}
              <div className="flex items-center">
                <input
                  type="radio"
                  name="package"
                  className="hidden"
                  value={pkg.slug_id}
                  checked={isSelected}
                  onChange={() => setSelectedPackageSlug(pkg.slug_id)}
                />
                <span
                  className={`
                    inline-block w-6 h-6 rounded-full mr-4
                    ${isSelected ? 'bg-red-500' : 'bg-gray-200'}
                  `}
                />
                <span className="text-xl font-bold">{pkg.name}</span>
              </div>

              <p className="ml-10 text-sm mt-2 text-gray-700 whitespace-pre-line">
                {pkg.description.trim()}
              </p>

              {/* If the package has upgrade levels (premium) and is selected, show tier selection */}
              {isPremium && isSelected && pkg.upgradeLevels && (
                <PremiumTierSelection
                  premiumPackage={pkg}
                  selectedUpgradeIndex={selectedUpgradeIndex}
                  onSelectUpgradeIndex={setSelectedUpgradeIndex}
                />
              )}
            </label>
          );
        })}
      </div>
    </div>
  );
});

export default PackageSelection;

/** 
 * Subcomponent for the premium-tier UI
 * Note that we accept a full CateringPackage and do optional checks.
 */
const PremiumTierSelection: React.FC<{
  premiumPackage: CateringPackage;  // Use the real interface
  selectedUpgradeIndex: number | null;
  onSelectUpgradeIndex: (idx: number | null) => void;
}> = observer(({ premiumPackage, selectedUpgradeIndex, onSelectUpgradeIndex }) => {
  const { upgradeLevels, subPackages } = premiumPackage;

  // If upgradeLevels is undefined or empty, nothing to show
  if (!upgradeLevels || upgradeLevels.length === 0) return null;

  return (
    <div className="mt-4 ml-10 space-y-3">
      <h3 className="font-semibold text-base text-red-600">
        Choose Your Upgrade Level
      </h3>

      {upgradeLevels.map((lvl, idx) => {
        const lvlSelected = idx === selectedUpgradeIndex;
        return (
          <div
            key={lvl.label}
            onClick={() => onSelectUpgradeIndex(idx)}
            className={`
              border rounded p-3 cursor-pointer hover:border-red-500 transition
              ${lvlSelected ? 'border-red-500' : 'border-gray-200'}
            `}
          >
            <div className="flex items-center">
              <span
                className={`
                  inline-block w-4 h-4 rounded-full mr-2
                  ${lvlSelected ? 'bg-red-500' : 'bg-gray-200'}
                `}
              />
              <strong className="mr-2">{lvl.label}</strong>
              <span className="text-sm text-gray-600">
                ${Number(lvl.pricePerPerson).toFixed(2)}/person
              </span>
            </div>
            {/* If this level is selected, show tier details */}
            {lvlSelected && (
              <div className="ml-6 mt-2 text-sm text-gray-700">
                <p>
                  <strong>Appetizer Limit:</strong> {lvl.appetizerLimit}
                </p>
                <p>
                  <strong>Entrée Limit:</strong> {lvl.entreeLimit}
                </p>
                <p>
                  <strong>Allowed Desserts:</strong>{' '}
                  {lvl.dessertOptions.join(', ')}
                </p>
              </div>
            )}
          </div>
        );
      })}

      {selectedUpgradeIndex !== null && selectedUpgradeIndex >= 0 && (
        <div className="mt-4 bg-gray-100 p-3 rounded">
          <h4 className="font-semibold text-sm text-gray-800 mb-2">
            What's Included?
          </h4>
          <TierDetails
            premiumPackage={premiumPackage}
            upgradeIndex={selectedUpgradeIndex}
          />
        </div>
      )}
    </div>
  );
});

/**
 * Renders the sub-packages (Appetizers, Entrees, Desserts) 
 * with constraints from the chosen tier
 */
const TierDetails: React.FC<{
  premiumPackage: CateringPackage;
  upgradeIndex: number;
}> = observer(({ premiumPackage, upgradeIndex }) => {
  const { subPackages, upgradeLevels } = premiumPackage;
  if (!upgradeLevels || !subPackages) return null;

  const lvl = upgradeLevels[upgradeIndex];
  if (!lvl) return null;

  const appetizers = subPackages.find(sp => sp.name === 'Appetizers');
  const entrees = subPackages.find(sp => sp.name === 'Entrees');
  const desserts = subPackages.find(sp => sp.name === 'Desserts');

  return (
    <div className="space-y-4 text-sm">
      {/* APPS */}
      {appetizers && (
        <div>
          <h5 className="font-semibold text-red-600">{appetizers.name}</h5>
          <p className="text-xs text-gray-500 mb-1">
            You can pick up to <strong>{lvl.appetizerLimit}</strong> of these:
          </p>
          <ul className="list-disc list-inside ml-4">
            {appetizers.items.map((app) => (
              <li key={app}>{app}</li>
            ))}
          </ul>
          {appetizers.notes && (
            <p className="text-xs text-gray-600 italic mt-1">{appetizers.notes}</p>
          )}
        </div>
      )}

      {/* ENTREES */}
      {entrees && (
        <div>
          <h5 className="font-semibold text-red-600">{entrees.name}</h5>
          <p className="text-xs text-gray-500 mb-1">
            You can pick up to <strong>{lvl.entreeLimit}</strong> of these:
          </p>
          <ul className="list-disc list-inside ml-4">
            {entrees.items.map((ent) => (
              <li key={ent}>{ent}</li>
            ))}
          </ul>
          {entrees.notes && (
            <p className="text-xs text-gray-600 italic mt-1">{entrees.notes}</p>
          )}
        </div>
      )}

      {/* DESSERTS */}
      {desserts && (
        <div>
          <h5 className="font-semibold text-red-600">{desserts.name}</h5>
          <p className="text-xs text-gray-500 mb-1">
            Only these desserts are allowed at this tier:
          </p>
          <ul className="list-disc list-inside ml-4">
            {desserts.items
              .filter((d) => lvl.dessertOptions.includes(d))
              .map((d) => (
                <li key={d}>{d}</li>
              ))}
          </ul>
          {desserts.notes && (
            <p className="text-xs text-gray-600 italic mt-1">{desserts.notes}</p>
          )}
        </div>
      )}
    </div>
  );
});